import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { collection, getDocs, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useUserLevel, AdminLevel } from '../../admin';

interface User {
  id: string;
  name: string;
  email: string;
  groupId: string | null;
  access: Record<string, boolean>;

}

interface Group {
  id: string;
  groupId: string;
  recoveryPlan: string;
  Admin: string;
  Members: string[];
  password?: string;
  access: Record<string, boolean>;

}

const defaultFeatureList: Record<string, boolean> = {
  "Analytics": false,
  "Arm Assessment": false,
  "Back Assessment": false,
  "Frequency": true,
  "IMU": true,
  "Knee Assessment": false,
  "Manufacture": false,
  "Power": true,
  "Research Data": true,
  "Schedule": false,
  "Treatment": false,
  "Wearables": false,
};

interface ModifyGroupProps {
  onClose: () => void;
}

const ModifyGroup: React.FC<ModifyGroupProps> = ({ onClose }) => {
  const { currentUserLevel } = useUserLevel();
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [groupName, setGroupName] = useState('');
  const [recoveryPlan, setRecoveryPlan] = useState('');
  const [groupPassword, setGroupPassword] = useState('');
  const [recoveryPlans, setRecoveryPlans] = useState<string[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedAdmin, setSelectedAdmin] = useState<string | null>(null); // Single admin email
  const [selectedMembers, setSelectedMembers] = useState<Set<string>>(new Set());
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [groups, setGroups] = useState<Group[]>([]);
  const [featureList, setFeatureList] = useState<Record<string, boolean>>(defaultFeatureList);

  // Fetch users, recovery plans, and groups on mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [usersSnapshot, recoveryPlansSnapshot, groupsSnapshot] = await Promise.all([
          getDocs(collection(db, 'users')),
          getDocs(collection(db, 'recoveryPlans')),
          getDocs(collection(db, 'groups')),
        ]);

        const usersData: User[] = usersSnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().name,
          email: doc.data().email,
          groupId: doc.data().groupId || null,
          access: doc.data().access || defaultFeatureList,
        }));

        const plans = recoveryPlansSnapshot.docs.map(doc => doc.id);
        const groupsData: Group[] = groupsSnapshot.docs.map(doc => ({
          id: doc.id,
          groupId: doc.data().groupId,
          recoveryPlan: doc.data().recoveryPlan,
          Admin: doc.data().Admin,
          Members: doc.data().Members || [],
          password: doc.data().password || '',
          access: doc.data().access || defaultFeatureList,
        }));

        setUsers(usersData);
        setRecoveryPlans(plans);
        setGroups(groupsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  // Update form fields when a group is selected
  useEffect(() => {
    if (selectedGroup) {
      setGroupName(selectedGroup.groupId);
      setRecoveryPlan(selectedGroup.recoveryPlan);
      setGroupPassword(selectedGroup.password || '');
      setSelectedAdmin(users.find(user => user.email === selectedGroup.Admin)?.id || null);
      setSelectedMembers(new Set(users.filter(user => selectedGroup.Members.includes(user.email)).map(user => user.id)));
      setFeatureList(selectedGroup.access || defaultFeatureList);
    }
  }, [selectedGroup, users]);

  // Filter users based on search query
  const filteredUsers = useMemo(() => {
    return users.filter(user =>
      user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [users, searchQuery]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const handleUserSelect = useCallback((userId: string, type: 'admin' | 'member') => {
    if (currentUserLevel === AdminLevel.USER) return;
  
    if (type === 'admin') {
      setSelectedAdmin(prev => (prev === userId ? null : userId));
      setSelectedMembers(prev => new Set([...prev, userId])); // Always add the admin to members
    } else {
      setSelectedMembers(prev => {
        const newSet = new Set(prev);
        newSet.has(userId) ? newSet.delete(userId) : newSet.add(userId);
        return newSet;
      });
    }
  }, [currentUserLevel]);

  // Handle feature toggle
  const handleFeatureToggle = useCallback((feature: string) => {
    setFeatureList(prev => ({
      ...prev,
      [feature]: !prev[feature],
    }));
  }, []);


      // Update group in Firestore
  const handleUpdateGroup = useCallback(async () => {
    if(!selectedAdmin){
      alert('No Admin selected!');
      return
    }

    if (!selectedGroup) return;

    try {
      const adminEmail = users.find(user => user.id === selectedAdmin)?.email;
      const memberEmails = Array.from(selectedMembers).map(memberId =>
        users.find(user => user.id === memberId)?.email
      );


      const updatedGroup = {
        groupId: groupName,
        recoveryPlan,
        Admin: adminEmail,
        Members: memberEmails.filter(Boolean) as string[],
        password: groupPassword,
        access: featureList,
      };

      await updateDoc(doc(db, 'groups', selectedGroup.id), updatedGroup);

      // Update user documents
      const userUpdates = users.map(user => {
        const userDoc = doc(db, 'users', user.id);
        if (selectedMembers.has(user.id)) {
          return updateDoc(userDoc, { groupId: groupName, recoveryPlan, access:featureList });
        } else if (user.groupId === selectedGroup.groupId) {
          return updateDoc(userDoc, { groupId: null, recoveryPlan: null, access:defaultFeatureList });
        }
        return Promise.resolve();
      });

      await Promise.all(userUpdates);
      onClose();
      window.location.reload();
      alert('Group updated');
    } catch (error) {
      console.error('Error updating group:', error);
    }
  }, [selectedGroup, selectedAdmin, users, groupName, recoveryPlan, groupPassword, featureList, selectedMembers, onClose]);

  // Delete group from Firestore
  const handleDeleteGroup = useCallback(async () => {
    if (!selectedGroup) return;

    try {
      // Clear group-related fields for members
      const userUpdates = users
        .filter(user => selectedGroup.Members.includes(user.email))
        .map(user =>
          updateDoc(doc(db, 'users', user.id), {
            groupId: null,
            recoveryPlan: null,
            recoveryPlanDate: null,
          })
        );

      await Promise.all(userUpdates);
      await deleteDoc(doc(db, 'groups', selectedGroup.id));
      setSelectedGroup(null);
      onClose();
      window.location.reload();
      alert('Group deleted');
    } catch (error) {
      console.error('Error deleting group:', error);
    }
  }, [selectedGroup, users, onClose]);

  useEffect(() => {
    if (users.length > 0) {
      setFeatureList(users[0].access || {});
    }
  }, [users]);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white h-5/6 w-11/12 lg:w-3/4 xl:w-2/3 p-8 rounded-3xl shadow-2xl relative overflow-auto">

        <button className="btn btn-sm btn-circle absolute right-2 top-2" onClick={onClose}>✕</button>

        <h2 className="text-3xl font-semibold text-gray-800 mb-6">Modify Group</h2>

        <div className="mb-6">
          <label className="block text-lg font-medium text-gray-700 mb-2">Select Group</label>
          <select
            value={selectedGroup?.id || ''}
            onChange={(e) => setSelectedGroup(groups.find(group => group.id === e.target.value) || null)}
            className="select select-bordered w-full"
            >
            <option value="" disabled>Select a group</option>
            {groups.map(group => (
              <option key={group.id} value={group.id}>{group.groupId}</option>
            ))}
          </select>
        </div>

        {selectedGroup && (
          <>
            <div className="mb-6">
              <label className="block text-lg font-medium text-gray-700 mb-2">Group Name</label>
              <div className="bg-blue-50 border border-blue-300 text-blue-700 p-3 rounded-md mb-4">
            <p className="text-sm">
              The group's name must be unique as it is used to identify the group.
            </p>
          </div>
              <input
                type="text"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                className="select select-bordered w-full"
                />
            </div>

            <div className="mb-6">
              <label className="block text-lg font-medium text-gray-700 mb-2">Recovery Plan</label>
              <div className="bg-blue-50 border border-blue-300 text-blue-700 p-3 rounded-md mb-4">
            <p className="text-sm">
              The recovery plan that will be assigned to all members of the group.
            </p>
          </div>
              <select
                value={recoveryPlan}
                onChange={(e) => setRecoveryPlan(e.target.value)}
                className="select select-bordered w-full"
                >
                <option value="" disabled>Select a recovery plan</option>
                {recoveryPlans.map(plan => (
                  <option key={plan} value={plan}>{plan}</option>
                ))}
              </select>
            </div>

            <div className="mb-6">
              <label className="block text-lg font-medium text-gray-700 mb-2">Group Password</label>
              <label className="block text-lg font-medium text-gray-700 mb-2">Group Password</label>
          <div className="bg-blue-50 border border-blue-300 text-blue-700 p-3 rounded-md mb-4">
            <p className="text-sm">
              The code that must be entered alongside the group's name when a user wants to join the group via app.
            </p>
          </div>
              <input
                value={groupPassword}
                onChange={(e) => setGroupPassword(e.target.value)}
                className="select select-bordered w-full"
                />
            </div>

            <div className="mb-6">
            <h3 className="text-xl font-medium text-gray-800 mb-4">Feature Access</h3>
            <div className="bg-blue-50 border border-blue-300 text-blue-700 p-3 rounded-md mb-4">
            <p className="text-sm">
              The user’s feature access will be ignored and instead determined by the group’s features list while they are members.
            </p>
          </div>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {Object.entries(featureList)
              .sort(([a], [b]) => a.localeCompare(b)) // Sort alphabetically
              .map(([feature, enabled]) => (
                <label key={feature} className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={enabled}
                    onChange={() => handleFeatureToggle(feature)}
                    className="checkbox checkbox-primary"
                  />
                  {feature}
                </label>
              ))}
            </div>
        
          </div>
            <div className="mb-6">
              <h3 className="text-xl font-medium text-gray-800 mb-4">Assign Admin and Members</h3>
              <div className="bg-blue-50 border border-blue-300 text-blue-700 p-3 rounded-md mb-4">
    
            <p className="text-sm">
              The group must have one admin assigned. Admins act as members but with more control over parameters of the group via dashboard.
            </p>
            <p className='text-sm'>
            The following users are not current members of any group.

            </p>
          </div>
              <input
                type="text"
                placeholder="Search users"
                value={searchQuery}
                onChange={handleSearch}
                className="select select-bordered w-full"
                />
              <div className="max-h-64 overflow-y-auto border border-gray-200 rounded-lg">
                {users
                  .filter(user => (user.name.toLowerCase().includes(searchQuery) || user.email.toLowerCase().includes(searchQuery)))
                  .map((user) => (
                  <div key={user.id} className="flex items-center justify-between p-3 border-b border-gray-100 hover:bg-gray-50">
                    <div>
                      <p className="text-sm font-medium text-gray-900">{user.name}</p>
                      <p className="text-sm text-gray-500">{user.email}</p>
                    </div>
                    <div className="flex gap-4">
                      <label className="flex items-center">
                        <span className="text-sm font-medium text-gray-600">Admin</span>
                        <input
                          type="checkbox"
                          checked={selectedAdmin === user.id}
                          onChange={() => handleUserSelect(user.id, 'admin')}
                          className="checkbox checkbox-primary"
                        />
                      </label>
                      <label className="flex items-center">
                        <span className="text-sm font-medium text-gray-600">Member</span>
                        <input
                          type="checkbox"
                          checked={selectedMembers.has(user.id)}
                          onChange={() => handleUserSelect(user.id, 'member')}
                          className="checkbox checkbox-primary"
                          disabled={selectedAdmin === user.id} // Admin is automatically a member
                        />
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex justify-start space-x-4">
              <button
                onClick={handleUpdateGroup}
                className="btn mx-2 p-2"
              >
                Update Group
              </button>

              <button
                onClick={handleDeleteGroup}
                className="btn mx-2 p-2 bg-red-500"
              >
                Delete Group
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ModifyGroup;

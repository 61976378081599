import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Import Firestore instance from your firebase configuration
import { useUserLevel, AdminLevel } from "../../admin";

interface User {
  id: string;
  name: string;
  email: string;
  groupId: string;
  userSequences: Record<string, number>;
}

const AssignSequence: React.FC<{ users: User[], uid: string, onClose: () => void }> = ({ users, uid, onClose }) => {
  const [filteredUsers, setFilteredUsers] = useState<User[]>(users);
  const [selectedUsers, setSelectedUsers] = useState<Set<string>>(new Set());
  const [productIndexes, setProductIndexes] = useState<Record<string, number>>({
    'VM1MOAABLE': 1,
    'VM2MOBABLE': 1,
    'VM4MOAABLE': 1,
    'VM6MOAABLE': 1,
  });
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [groupFilter, setGroupFilter] = useState<string>('');
  const [sequenceCounts, setSequenceCounts] = useState<Record<string, number>>({
    'VM1MOAABLE': 1,
    'VM2MOBABLE': 1,
    'VM4MOAABLE': 1,
    'VM6MOAABLE': 1,
  });
  const { currentUserLevel } = useUserLevel();
  const [adminGroupId, setAdminGroupId] = useState<string | null>(null);

  useEffect(() => {
    const fetchSequenceCounts = async () => {
      const sequencesCollection = collection(db, 'sequences');
      const sequencesSnapshot = await getDocs(sequencesCollection);
      const counts: Record<string, number> = {};

      sequencesSnapshot.forEach(doc => {
        counts[doc.id] = Object.keys(doc.data()).filter(key => key.startsWith('sequence')).length;
      });

      setSequenceCounts(counts);
    };

    fetchSequenceCounts();
  }, []);

  useEffect(() => {
    const fetchAdminGroup = async () => {
      const userDoc = await getDoc(doc(db, 'users', uid));
      if (userDoc.exists()) {
        const userData = userDoc.data() as User;
        setAdminGroupId(userData.groupId);
      }
    };

    if (currentUserLevel === AdminLevel.ADMIN) {
      fetchAdminGroup();
    }
  }, [uid, currentUserLevel]);

  useEffect(() => {
    let filtered = users;

    if (searchQuery) {
      filtered = filtered.filter(user =>
        user.name.toLowerCase().includes(searchQuery) || user.email.toLowerCase().includes(searchQuery)
      );
    }

    if (groupFilter && currentUserLevel === AdminLevel.SUPER_ADMIN) {
      filtered = filtered.filter(user => 
        user.groupId && user.groupId.toLowerCase().includes(groupFilter.toLowerCase())
      );
    } else if (currentUserLevel === AdminLevel.ADMIN && adminGroupId) {
      filtered = filtered.filter(user => user.groupId === adminGroupId);
    }

    setFilteredUsers(filtered);
  }, [users, searchQuery, groupFilter, adminGroupId, currentUserLevel]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleGroupFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupFilter(e.target.value.toLowerCase());
  };

  const handleUserSelect = (userId: string) => {
    const newSelectedUsers = new Set(selectedUsers);
    if (newSelectedUsers.has(userId)) {
      newSelectedUsers.delete(userId);
    } else {
      newSelectedUsers.add(userId);
    }
    setSelectedUsers(newSelectedUsers);
  };

  const handleSelectAll = () => {
    if (selectedUsers.size === filteredUsers.length) {
      setSelectedUsers(new Set());
    } else {
      const allUserIds = new Set(filteredUsers.map(user => user.id));
      setSelectedUsers(allUserIds);
    }
  };

  const handleProductIndexChange = (productCode: string, index: number) => {
    setProductIndexes(prev => ({ ...prev, [productCode]: index }));
  };

  const handleAssign = async () => {
    for (const userId of Array.from(selectedUsers)) {
      const userDoc = doc(db, 'users', userId);
      const updatedSequences = { ...productIndexes };
      await updateDoc(userDoc, { userSequences: updatedSequences });
    }
    onClose(); // Close the display after assigning sequences
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white h-5/6 w-11/12 lg:w-3/4 xl:w-2/3 p-8 rounded-3xl shadow-2xl relative overflow-auto">
        <button className="btn btn-sm btn-circle absolute right-2 top-2" onClick={onClose}>
          ✕
        </button>
        <h2 className="text-3xl font-semibold text-gray-800 mb-6">Assign Sequences</h2>
        <div className="bg-blue-50 border border-blue-300 text-blue-700 p-3 rounded-md mb-4">
              <p className="text-sm">
                Assign motor sequences via their index to each Myovolt product for select users.
              </p>
            </div>
  
        <div className="flex flex-wrap lg:flex-nowrap space-y-6 lg:space-y-0">
          {/* User List Section */}
          <div className="lg:w-2/3 pr-6">
            {currentUserLevel === AdminLevel.SUPER_ADMIN && (
              <div className="mb-4">
                <input
                  type="text"
                  value={groupFilter}
                  onChange={handleGroupFilterChange}
                  placeholder="Filter by group"
                  className="input input-bordered w-full"
                />
              </div>
            )}
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearch}
              placeholder="Search users"
              className="input input-bordered w-full mb-4"
            />
            <button onClick={handleSelectAll} className="btn btn-primary mb-4 w-full">
              {selectedUsers.size === filteredUsers.length ? 'Deselect All' : 'Select All'}
            </button>
            <div className="overflow-y-auto max-h-96 bg-gray-50 p-4 rounded-lg shadow-inner">
              <ul className="space-y-4">
                {filteredUsers.map(user => (
                  <li key={user.id} className="bg-white rounded-lg p-4 shadow-md flex items-start">
                    <input
                      type="checkbox"
                      checked={selectedUsers.has(user.id)}
                      onChange={() => handleUserSelect(user.id)}
                      className="checkbox checkbox-primary mr-4 mt-1"
                    />
                    <div>
                      <span className="block font-medium text-gray-800">{user.name}</span>
                      <span className="block text-sm text-gray-600">{user.email}</span>
                      <span className="block text-sm text-gray-600">Group: {user.groupId}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
  
          {/* Product Index Section */}
          <div className="lg:w-1/3 bg-gray-100 p-4 rounded-lg shadow-inner">
            {Object.keys(productIndexes).map(productCode => (
              <div key={productCode} className="mb-6">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {productCode}
                </label>
                <select
                  value={productIndexes[productCode]}
                  onChange={(e) => handleProductIndexChange(productCode, parseInt(e.target.value))}
                  className="select select-bordered w-full"
                >
                  {Array.from(Array(sequenceCounts[productCode]).keys()).map(i => (
                    <option key={i} value={i + 1}>{i + 1}</option>
                  ))}
                </select>
              </div>
            ))}
            <button onClick={handleAssign} className="btn btn-primary p-2 w-full">
              Assign Sequences
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignSequence;
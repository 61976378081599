import React, { useEffect, useState } from "react";
import { Tooltip } from 'react-tooltip';
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { scaleLinear } from "d3-scale";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import 'react-tooltip/dist/react-tooltip.css';

interface geoDataItem {
  country: string;  // ISO Alpha-2 code (e.g., 'US', 'DE') compatible with react-simple-maps
  assessmentTotal: number;
}

// Helper function to extract country code from locale string (e.g., en_US -> US)
const extractCountryCode = (locale: string): string | null => {
  const parts = locale.split('_');
  return parts.length > 1 ? parts[1] : null;  // Extract the country code if it exists
};

const aGlobalAssessments = async (): Promise<{ geoData: geoDataItem[], totalAssessments: number }> => {
  // Define the collections to be included
  const collections = ['back_assessment', 'arm_assessment', 'knee_assessment'];

  const geoData: geoDataItem[] = [];
  let totalAssessments = 0;

  for (const collectionName of collections) {
    const assessmentsCollection = collection(db, collectionName);
    const querySnapshot = await getDocs(assessmentsCollection);

    querySnapshot.forEach((docSnapshot) => {
      const data = docSnapshot.data();
      if (data && data.locale) {
        const countryCode = extractCountryCode(data.locale); // Get the country code

        if (countryCode) {
          // Check if the country already exists in the geoData array
          const existingGeoDataItem = geoData.find(item => item.country === countryCode);

          if (existingGeoDataItem) {
            // If the country exists, increment the assessmentTotal
            existingGeoDataItem.assessmentTotal += 1;
          } else {
            // If the country doesn't exist, create a new geoDataItem with assessmentTotal of 1
            geoData.push({
              country: countryCode, // Use the extracted country code
              assessmentTotal: 1
            });
          }
        }

        // Increment the global total assessment count
        totalAssessments += 1;
      }
    });
  }

  // Return both geoData and the total number of assessments globally
  return { geoData, totalAssessments };
};

const geoUrl = "https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_countries_lakes.geojson";

interface WorldAssessmentsProps {
  onClose: () => void;
}

const WorldAssessments: React.FC<WorldAssessmentsProps> = ({ onClose }) => {
  const [globalAssessments, setGlobalAssessments] = useState<geoDataItem[]>([]);
  const [totalAssessments, setTotalAssessments] = useState<number>(0);
  const [maxAssessments, setMaxAssessments] = useState<number>(1);
  const [tooltipContent, setTooltipContent] = useState<string | null>(null);  // State for tooltip content

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { geoData, totalAssessments } = await aGlobalAssessments();  // Destructure returned values
        setGlobalAssessments(geoData);  // Update the geoData state
        setTotalAssessments(totalAssessments);  // Update the total assessments state

        // Find the maximum number of assessments for color scaling
        const maxAssessments = Math.max(...geoData.map(item => item.assessmentTotal || 0));
        setMaxAssessments(maxAssessments);

      } catch (error) {
        console.error("Error fetching assessment data:", error);
      }
    };

    fetchData();
  }, []);

  // Create a color scale based on the max assessment count
  const colorScale = scaleLinear<string>()
    .domain([1, maxAssessments]) // Scale from 0 to the maximum assessment count
    .range(['#D6F3F5', '#56c1ca']); // Color range for the map, light to dark

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white h-5/6 p-4 rounded-lg shadow-lg relative">
        {/* Tooltip Component */}
        {tooltipContent && (
          <Tooltip
            place="top"
            id="country-tooltip" // Unique ID for the tooltip
            content={tooltipContent} // Dynamically set content
          />
        )}
        {/* Header Section */}
        <div className="flex items-center justify-between w-full mb-4">
      

          {/* Centered Title */}
          <h2 className="text-l font-semibold text-center">World Assessments</h2>
          <h3 className="text-m text-center">{tooltipContent}</h3>

          {/* Close Button */}
          <button
            className="btn btn-sm btn-circle"
            onClick={onClose}
          >
            ✕
          </button>
        </div>
        <div className="bg-blue-50 border border-blue-300 text-blue-700 p-3 rounded-md mb-4">
          <p className="text-sm">
            <span className="text-blue-600 font-semibold">{totalAssessments}</span> total assessments completed via the Assessment features.
          </p>    
        </div>
        <div className="w-full h-full">
          {/* The Map */}
          <ComposableMap
            projectionConfig={{
              scale: 200,  // Adjust zoom level as needed
              center: [0, 0], // Center the map
            }}
            width={1000}  // Adjusted to fit inside the white box
            height={800}  // Adjusted to fit inside the white box
            style={{ width: "100%", height: "100%" }}  // Make the map responsive
          >
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies
                  .filter((geo) => geo.properties.continent !== "Antarctica") // Filter out Antarctica
                  .map((geo) => {
                    const countryCode = geo.properties.iso_a2;
                    const countryData = globalAssessments.find(item => item.country === countryCode);  // Find the corresponding country in geoData
                    const assessmentTotal = countryData ? countryData.assessmentTotal || 0 : 0;
                    const fillColor = assessmentTotal === 0
                      ? '#EFEFEF' // Very light color for zero assessments
                      : colorScale(assessmentTotal); // Scale based on assessment count for non-zero

                    return (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        fill={fillColor} // Color based on assessment total
                        stroke="#FFF"
                        strokeWidth={0.5}
                        onMouseDownCapture={() => {
                          setTooltipContent(`${geo.properties.name}: ${assessmentTotal} assessments`);
                        }}
                        style={{
                          default: { outline: 'none' },
                          hover: { fill: '#6A6AAA', outline: 'none' }, // Hover effect
                          pressed: { fill: '#5A5AAA', outline: 'none' }, // Pressed effect
                        }}
                      />
                    );
                  })
              }
            </Geographies>
          </ComposableMap>
        </div>
      </div>
    </div>
  );
};

export default WorldAssessments;
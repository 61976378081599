import { useEffect, useState, useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { getUserProfile, AuthContext, UserProfile as UP } from "../context/auth";
import { Query, collection, doc, getDoc, getDocs, getDocsFromCache, query, where, updateDoc } from "firebase/firestore";
import { db, getSource } from "../firebase";
import { exportResearchToCsv } from "../utils";

import { md5 } from 'js-md5';
import { DateTime } from "luxon";
import './styles.css'; 
import React from 'react';

import TreatmentGraph from "../components/graphs/TreatmentGraph";
import MetricsGraph from '../components/graphs/MetricsGraph';
import AssessmentGraph from "../components/graphs/AssessmentGraph";
import PainGraph from "../components/graphs/PainGraph";
import Schedule from "../components/schedule/Schedule";

import { useUserLevel, AdminLevel } from "../admin";

export let isGroupDataView = false;

export const setGroupDataView = (value: boolean) => {
  isGroupDataView = value;
};

export interface Treatment {
  id: string;
  startTime: DateTime;
  endTime: DateTime;
  sequence: { [product: string]: number };
  length: number;
  deviceId: string;
  temperaturePre: number;
  temperaturePost: number;
  painScorePre: number;
  painScorePost: number;
  deviceTN: number;
}

export interface Assessment {
  id: string;
  mode: string;
  startTime: DateTime | null;
  deviceId: string;
  flexibilityScore1: number;
  flexibilityScore2: number;
  flexibilityScore3: number;
  flexibilityScore4: number;
  flexibilityScore5: number;
  flexibilityScore6: number;
}

export async function fixCollections() {
  // Reference the `treatments` collection
  const ref = collection(db, "knee_assessment");

  // Fetch all docs in this collection
  const snapshot = await getDocs(ref);

  // Prepare an array of update promises (so we can run them in parallel)
  const updatePromises: Promise<void>[] = [];

  // Loop through every doc
  snapshot.forEach((docSnap) => {
    const data = docSnap.data();
        updatePromises.push(
          updateDoc(docSnap.ref, { 
            flexibilityScore1: Math.round(data.flexibilityScore1),
            flexibilityScore2: Math.round(data.flexibilityScore2),
              flexibilityScore3: Math.round(data.flexibilityScore3),
              flexibilityScore4: Math.round(data.flexibilityScore4),
              // flexibilityScore5: Math.round(data.flexibilityScore5),
              // flexibilityScore6: Math.round(data.flexibilityScore6)
             })
        );
  });

  // Await all updates in parallel
  await Promise.all(updatePromises);

}
//fixCollections()

/** 
 * Helper: parse dd/mm/yyyy into JS Date 
 * Only used if collectionName === "metrics"
 */
function parseDdMmYyyy(dateStr: string): Date {
  const [day, month, year] = dateStr.split("/").map(Number);
  return new Date(year, month - 1, day);
}

/**
 * Fetches Firestore docs from a specific collection for the given userId,
 * caches them in sessionStorage for 10 minutes, and returns [data, loading, error].
 */
export function useUserCollectionWithCache(
  userId: string,
  collectionName: string
): [any[], boolean, Error | null] {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  // 10 minutes in ms
  const CACHE_DURATION = 10 * 60 * 1000;
  //const CACHE_DURATION = 1000;
  const cacheKey = md5(`${userId}-${collectionName}`);

  useEffect(() => {
    if (!userId || !collectionName) {
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);

    // Check sessionStorage for existing data
    const cachedString = sessionStorage.getItem(cacheKey);
    if (cachedString) {
      try {
        const { timestamp, payload } = JSON.parse(cachedString);
        const age = Date.now() - timestamp;
        // If still fresh, use it
        if (age < CACHE_DURATION) {
          setData(payload);
          setLoading(false);
          return;
        }
      } catch {
        // If JSON parse fails, we'll just do a fresh fetch
      }
    }

    // Otherwise, fetch from Firestore
    const fetchData = async () => {
      try {
        const colRef = collection(db, collectionName);
        const q = query(colRef, where("userId", "==", userId));
        const snapshot = await getDocs(q);

        // Convert docs to array (raw data)
        let docs = snapshot.docs.map((doc) => ({
          ...doc.data(),
        }));

        // --- 1) Transform if "treatments" ---
        if (collectionName !== "metrics") {
          if (collectionName === "treatments") {
            docs = docs.map((item: any) => {
              return {
                id: item.id,
                startTime: DateTime.fromSeconds(item.startTime.seconds),
                endTime: DateTime.fromSeconds(item.endTime.seconds),
                sequence: item.sequence,
                length: item.length,
                deviceId: item.deviceId,
                temperaturePre: item.temperaturePre,
                temperaturePost: item.temperaturePost,
                painScorePre: item.painScorePreId,
                painScorePost: item.painScorePostId,
                deviceTN: item.deviceTN,
              } as Treatment;
            });
          } else if (collectionName === "back_assessment" || collectionName === "knee_assessment" || collectionName === "arm_assessment") {
            docs = docs.map((item: any) => {
              return {
                id: item.id,
                startTime: DateTime.fromSeconds(item.startTime.seconds),
                mode: item.mode,
                deviceId: item.deviceId,
                flexibilityScore1: item.flexibilityScore1,
                flexibilityScore2: item.flexibilityScore2,
                flexibilityScore3: item.flexibilityScore3,
                flexibilityScore4: item.flexibilityScore4,
                flexibilityScore5: item.flexibilityScore5,
                flexibilityScore6: item.flexibilityScore6,
              } as Assessment;
            });
          }

          // Sort by startTime (ascending)
          docs.sort((a, b) => {
            if (!a.startTime || !b.startTime) return 0;
            return a.startTime.toMillis() - b.startTime.toMillis();
          });
        }
        // --- 2) If "metrics", sort by 'date' dd/mm/yyyy ---
        else if (collectionName === "metrics") {
          docs.sort((a, b) => {
            if (!a.date || !b.date) return 0;
            const dateA = parseDdMmYyyy(a.date);
            const dateB = parseDdMmYyyy(b.date);
            return dateA.getTime() - dateB.getTime();
          });
        }
        // --- 3) Otherwise, sort by startTime if present (Timestamp) ---
        else {
          docs.sort((a: any, b: any) => {
            if (!a.startTime || !b.startTime) return 0;
            const aMs = a.startTime?.seconds
              ? a.startTime.seconds * 1000
              : 0;
            const bMs = b.startTime?.seconds
              ? b.startTime.seconds * 1000
              : 0;
            return aMs - bMs;
          });
        }

        setData(docs);

        // Cache
        sessionStorage.setItem(
          cacheKey,
          JSON.stringify({
            timestamp: Date.now(),
            payload: docs,
          })
        );
      } catch (err) {
        if (err instanceof Error) {
          setError(err);
        } else {
          setError(new Error("Unknown error occurred"));
        }
        setData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, collectionName, cacheKey]);




  return [data, loading, error];
}

const useGroupMetrics = (groupId: string | null | undefined) => {
    const [data, setData] = useState<any[]>([]);
    const [ids, setIds] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    //const { currentUser, profile } = useContext(AuthContext);

    // Cache key based on groupId
    const cacheKey = `groupMetrics_${groupId}`;
    const cacheTimestampKey = `groupMetricsTimestamp_${groupId}`;


    useEffect(() => {
        
        const fetchGroupData = async () => {
            try {
                setLoading(true);

                // const userSnap = await getDoc(doc(db, 'users', currentUser!.uid));
                // setGroupId(userSnap.data()?.groupId)
                // if(groupId === null){
                //     return 
                // }

                // Check if the data is cached and if it's within the 10-minute window
                const cachedData = sessionStorage.getItem(cacheKey);
                const cachedTimestamp = sessionStorage.getItem(cacheTimestampKey);
                const now = Date.now();

                // If cached data is within 10 minutes, use it
                if (cachedData && cachedTimestamp && (now - parseInt(cachedTimestamp)) < 600000) { // 10 minutes = 600,000 ms
                    console.log('Using cached data');
                    setData(JSON.parse(cachedData));
                    setLoading(false);
                    return;
                }

                // Otherwise, fetch fresh data from Firebase
                console.log('Fetching fresh data');
                const q = query(
                    collection(db, "groups"),
                    where('groupId', '==', groupId),
                );
                const groupQuerySnapshot = await getDocs(q);

                if (!groupQuerySnapshot.empty) {
                    const groupDoc = groupQuerySnapshot.docs[0];
                    const members = groupDoc.data()?.Members || [];

                    // Retrieve member IDs from the users collection by matching their emails
                    const idList: string[] = [];
                    for (const m of members) {
                        const userQuery = query(
                            collection(db, "users"),
                            where('email', '==', m),
                        );
                        const memberQuerySnapshot = await getDocs(userQuery);
                        if (!memberQuerySnapshot.empty) {
                            idList.push(memberQuerySnapshot.docs[0].id);
                        }
                    }
                    setIds(idList);
                } else {
                    console.log("No group found with the specified groupId");
                }
            } catch (err) {
                console.error('Error fetching group members:', err);
                setError(err as Error);
            } finally {
                setLoading(false);
            }
        };

        fetchGroupData();
    }, [groupId]); // Re-run when groupId changes

    // Fetch the metrics for each member and apply caching
    useEffect(() => {
        const fetchMemberMetrics = async () => {
            try {
                if (ids.length === 0) return;
    
                const allMetrics: any[] = [];
    
                for (const id of ids) {
                    // Query the metrics for each member
                    const q = query(
                        collection(db, "metrics"),
                        where('userId', '==', id),
                    );
                    const metricsSnapshot = await getDocs(q);
    
                    if (!metricsSnapshot.empty) {
                        const memberMetrics = metricsSnapshot.docs.map(doc => doc.data());
                        allMetrics.push(...memberMetrics); // Aggregate the metrics
                    }
                }
    
                // Group the metrics by date and merge data
                const groupedMetrics: any = {};
    
                allMetrics.forEach((metric) => {
                    const date = metric.date; // Assuming date is a string in 'dd/MM/yyyy' format
    
                    if (!groupedMetrics[date]) {
                        groupedMetrics[date] = {
                            date,
                            mobility: { count: 0, total: 0, average: 0, single: 0 },
                            pain: { count: 0, total: 0, average: 0, single: 0 },
                            treatment: { count: 0, total: 0, average: 0, single: 0 }
                        };
                    }
    
                    // Combine mobility
                    groupedMetrics[date].mobility.count += metric.mobility.count || 0;
                    groupedMetrics[date].mobility.total += metric.mobility.average || 0;
                    groupedMetrics[date].mobility.single = metric.mobility.single || 0;
    
                    // Combine pain
                    groupedMetrics[date].pain.count += metric.pain.count || 0;
                    groupedMetrics[date].pain.total += metric.pain.average || 0;
                    groupedMetrics[date].pain.single = metric.pain.single || 0;
    
                    // Combine treatment (summed across group members)
                    groupedMetrics[date].treatment.count += metric.treatment.count || 0;
                    groupedMetrics[date].treatment.total += metric.treatment.total || 0;
                    groupedMetrics[date].treatment.single = metric.treatment.single || 0;
                });
    
                // Calculate final averages and totals
                const finalMetrics = Object.values(groupedMetrics).map((metric: any) => {
                    // Calculate mobility and pain averages
                    const mobilityAverage = metric.mobility.count ? metric.mobility.total / metric.mobility.count : 0;
                    const painAverage = metric.pain.count ? metric.pain.total / metric.pain.count : 0;
    
                    // Apply the formula for recovery
                    const recovery = ((10 - painAverage) + mobilityAverage) / 2;
    
                    return {
                        date: metric.date,
                        mobility: {
                            count: metric.mobility.count,
                            total: metric.mobility.total,
                            average: mobilityAverage,
                            single: metric.mobility.single
                        },
                        pain: {
                            count: metric.pain.count,
                            total: metric.pain.total,
                            average: painAverage,
                            single: metric.pain.single
                        },
                        recovery: recovery, // Computed recovery using the formula
                        treatment: {
                            count: metric.treatment.count,
                            total: metric.treatment.total,
                            average: metric.treatment.count ? metric.treatment.total / metric.treatment.count : 0,
                            single: metric.treatment.single
                        }
                    };
                });
    
                finalMetrics.sort((a: { date: string }, b: { date: string }) => {
                    const dateA = new Date(a.date.split('/').reverse().join('-'));
                    const dateB = new Date(b.date.split('/').reverse().join('-'));
                    return dateA.getTime() - dateB.getTime();
                });
    
                // Store data in cache (sessionStorage)
                sessionStorage.setItem(cacheKey, JSON.stringify(finalMetrics));
                sessionStorage.setItem(cacheTimestampKey, DateTime.now.toString());
    
                setData(finalMetrics); // Set the merged and calculated data
    
            } catch (err) {
                console.error('Error fetching member metrics:', err);
                setError(err as Error);
            }
        };
    
        if (ids.length > 0) {
            fetchMemberMetrics();
        }
    }, [ids]); // Re-run when the list of member IDs changes

    // Clear metrics data when the groupId changes
    useEffect(() => {
        setData([]);
    }, [groupId]);

    return { data, loading, error };
};

const useResearchData = (id: string | null | undefined) => {
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    // Cache key based on groupId
    const cacheKey = `researchData_${id}`;
    const cacheTimestampKey = `researchDataTimestamp_${id}`;

    useEffect(() => {
        const fetchResearchData = async () => {
            try {
                setLoading(true);

                // Check if the data is cached and if it's within the 10-minute window
                const cachedData = sessionStorage.getItem(cacheKey);
                const cachedTimestamp = sessionStorage.getItem(cacheTimestampKey);
                const now = Date.now();
                let Data: any[] = [];

                // If cached data is within 1 minute, use it
                if (cachedData && cachedTimestamp && (now - parseInt(cachedTimestamp)) < 60000) { 
                    console.log('Using cached research data');
                    Data = JSON.parse(cachedData);
                
                    // Check if the cached data is valid (not empty or undefined)
                    if (Data && Data.length > 0) {
                        setData(Data);
                        setLoading(false);
                        return;
                    } else {
                        console.log('Cached data is empty or invalid');
                    }
                }

                // Otherwise, fetch fresh data from Firebase
                console.log('Fetching fresh research data');
                const q = query(
                    collection(db, "research_data"),
                    where('userId', '==', id),
                );
                const researchDocs = await getDocs(q);
 
                if (!researchDocs.empty) {
                    Data = researchDocs.docs.map(doc => doc.data()).map(data => {
                        const { freqArray, freqAverage, freqSingle, powerArray, powerSingle, length, startTime, ...rest } = data;

                        // Convert Firebase Timestamp to a JavaScript Date and then to the desired format
                        const startTimeFormatted = DateTime.fromJSDate(startTime.toDate()).toFormat('dd/MM/yyyy HH:mm');
                        return {
                            freqArray: freqArray,
                            freqAverage: freqAverage,  
                            freqSingle: freqSingle,
                            powerArray: powerArray,
                            powerSingle: powerSingle,  
                            length: length, 
                            startTime: startTimeFormatted,  // Formatted date
                            ...rest            
                        };
                    });

                } else {
                    console.log("No research docs found");
                }

                // Sort the combined data by date
                const sortData = Data.sort((a, b) => {
                    const dateA = DateTime.fromFormat(a.startTime, 'dd/MM/yyyy HH:mm');
                    const dateB = DateTime.fromFormat(b.startTime, 'dd/MM/yyyy HH:mm');
                    return dateB.toMillis() - dateA.toMillis();  // Sort in descending order
                });

                // Cache the sorted data
                sessionStorage.setItem(cacheKey, JSON.stringify(sortData));
                sessionStorage.setItem(cacheTimestampKey, now.toString());


                setData(sortData);
                setLoading(false);

            } catch(e) {
                console.log('Error fetching research data', e);
                setError(e as Error);
                setLoading(false);
            }
        };
        fetchResearchData();
    }, [id]);  // Add 'id' as a dependency to avoid repeated calls

    // // Clear data data when the id changes
    // useEffect(() => {
    //     setData([]);
    // }, [id]);

    useEffect(() => {
        //console.log('Data state updated:', data);
    }, [data]);

    return { data, loading, error };
};

export default function UserProfile() {
    let { id } = useParams();
    const [userProfile, setUserProfile] = useState<null | UP>(null);
  
    // Firestore hooks (already set up):
    const [treatmentData, loadingTreatments, errorTreatments] = useUserCollectionWithCache(userProfile?.id ?? "", "treatments");
    const [metricsData, loadingMetrics, errorMetrics] = useUserCollectionWithCache(userProfile?.id ?? "", "metrics");
    const [backAssessmentData, loadingBackAssessments, errorBackAssessments] = useUserCollectionWithCache(userProfile?.id ?? "", "back_assessment");
    const [kneeAssessmentData, loadingKneeAssessments, errorKneeAssessments] = useUserCollectionWithCache(userProfile?.id ?? "", "knee_assessment");
    const [armAssessmentData, loadingArmAssessments, errorArmAssessments] = useUserCollectionWithCache(userProfile?.id ?? "", "arm_assessment");
  
    // Additional hooks, unchanged:
    const { data: groupMetricsData } = useGroupMetrics(userProfile?.groupId);
    const { data: researchData } = useResearchData(id);
  
    useEffect(() => {
      if (id) {
        getUserProfile(id).then((user) => {
          setUserProfile(user);
        });
      }
    }, [id]);
  
    return (
      <div className="h-screen overflow-y-auto px-4 pt-4 space-y-4">
        {/* ------------------ Profile Information ------------------ */}
        <div style={{ maxWidth: '400px', margin: '8 auto' }}>
          <div className="collapse bg-white">
            <input type="radio" name="accordion" />
            <div className="collapse-title text-xl font-bold">Profile Information</div>
            <div className="collapse-content">
              <dl>
                <dd className="mb-5">{userProfile?.researcher ? 'Researcher' : 'Back Recover User'}</dd>
                <dt style={{ fontWeight: 'bold' }}>Name</dt>
                <dd className="mb-5">{userProfile?.name}</dd>
                <dt style={{ fontWeight: 'bold' }}>Email</dt>
                <dd className="mb-5">{userProfile?.email}</dd>
                <dt style={{ fontWeight: 'bold' }}>Occupation</dt>
                <dd className="mb-5">{userProfile?.occupation}</dd>
                <dt style={{ fontWeight: 'bold' }}>DOB</dt>
                <dd className="mb-5">
                  {((userProfile?.birthday as any)?.toDate() as Date)?.toDateString()} (
                  {Math.trunc((DateTime.now().toMillis() - (userProfile?.birthday as any)?.toMillis()) / 31536000000)}
                  )
                </dd>
                <dt style={{ fontWeight: 'bold' }}>Sex</dt>
                <dd className="mb-5">{userProfile?.sex}</dd>
                <dt style={{ fontWeight: 'bold' }}>Group</dt>
                <dd className="mb-5">{userProfile?.groupId}</dd>
                <dt className="text-sm text-slate-500 font-bold">Sequences</dt>
                <dt className="text-xs">1MO: Sequence {userProfile?.userSequences?.VM1MOAABLE}</dt>
                <dt className="text-xs">4MO: Sequence {userProfile?.userSequences?.VM4MOAABLE}</dt>
                <dt className="text-xs">6MO: Sequence {userProfile?.userSequences?.VM6MOAABLE}</dt>
              </dl>
            </div>
          </div>
        </div>
  
        {/* ------------------ Metrics ------------------ */}
        <div className="collapse bg-white">
          <input type="radio" name="accordion" />
          <div className="collapse-title text-xl font-bold">Metrics</div>
          <div className="collapse-content">
            {loadingMetrics ? (
              <div>Loading…</div>
            ) : errorMetrics ? (
              <div>Error: {errorMetrics.message}</div>
            ) : (
              <MetricsGraph
                userData={metricsData}
                groupData={groupMetricsData}
                userName={userProfile?.name}
                groupId={userProfile?.groupId}
                userEmail={userProfile?.email}
              />
            )}
          </div>
        </div>
  
        {/* ------------------ Schedule ------------------ */}
        <div className="collapse bg-white">
          <input type="radio" name="accordion" />
          <div className="collapse-title text-xl font-bold">Schedule</div>
          <div className="collapse-content">
            <Schedule userId={userProfile?.id} />
          </div>
        </div>
  
        {/* ------------------ Treatments ------------------ */}
        <div className="collapse bg-white">
          <input type="radio" name="accordion" />
          <div className="collapse-title text-xl font-bold">Treatments</div>
          <div className="collapse-content">
            {loadingTreatments ? (
              <div>Loading…</div>
            ) : errorTreatments ? (
              <div>Error: {errorTreatments.message}</div>
            ) : (
              <TreatmentGraph
                data={treatmentData}
                userEmail={userProfile?.email}
              />
            )}
          </div>
        </div>
  
        {/* ------------------ Assessments ------------------ */}
        <div className="collapse bg-white">
          <input type="radio" name="accordion" />
          <div className="collapse-title text-xl font-bold">Assessments</div>
          <div className="collapse-content">
            {/* 
              Because we have THREE different loading states (back, knee, arm),
              we can combine them with an OR. If any are loading => show "Loading…".
              Same idea for errors: check each error in turn. 
            */}
            {loadingBackAssessments || loadingKneeAssessments || loadingArmAssessments ? (
              <div>Loading…</div>
            ) : errorBackAssessments ? (
              <div>Error: {errorBackAssessments.message}</div>
            ) : errorKneeAssessments ? (
              <div>Error: {errorKneeAssessments.message}</div>
            ) : errorArmAssessments ? (
              <div>Error: {errorArmAssessments.message}</div>
            ) : (
              <AssessmentGraph
                backData={backAssessmentData}
                kneeData={kneeAssessmentData}
                armData={armAssessmentData}
                userEmail={userProfile?.email}
              />
            )}
          </div>
        </div>
  
        {/* ------------------ Pain ------------------ */}
        <div className="collapse bg-white">
          <input type="radio" name="accordion" />
          <div className="collapse-title text-xl font-bold">Pain</div>
          <div className="collapse-content">
            {loadingTreatments ? (
              <div>Loading…</div>
            ) : errorTreatments ? (
              <div>Error: {errorTreatments.message}</div>
            ) : (
              <PainGraph data={treatmentData} />
            )}
          </div>
        </div>
  
        {/* ------------------ Research Data ------------------ */}
        <div className="collapse bg-white">
          <input type="radio" name="accordion" />
          <div className="collapse-title text-xl font-bold">Research Data</div>
          <div className="collapse-content">
            <span
              className="text-blue-500 cursor-pointer underline"
              onClick={() => {
                if (userProfile?.email) {
                  exportResearchToCsv("research.csv", researchData as any[], userProfile.email);
                }
              }}
            >
              Export to CSV
            </span>
            <div className="max-h-96 overflow-y-auto mt-4">
              <table className="table table-zebra w-full text-sm">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Duration (minutes)</th>
                    <th>Frequency Target</th>
                    <th>Frequency Average</th>
                    <th>Power</th>
                  </tr>
                </thead>
                <tbody>
                  {researchData.map((row) => (
                    <tr key={row.id}>
                      <td>{row.startTime}</td>
                      <td>{row.length}:00</td>
                      <td>{row.freqSingle ? `${row.freqSingle} Hz` : '-'}</td>
                      <td>{row.freqAverage ? `${row.freqAverage} Hz` : '-'}</td>
                      <td>{row.powerSingle ? `${row.powerSingle}%` : '-'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }

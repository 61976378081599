import React, { useState, useEffect } from 'react';
import {
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  XAxis,
  YAxis,
  Line,
} from 'recharts';
import { DateTime } from 'luxon';
import { Treatment } from '../../routes/userprofile';

//-------------- Types --------------

interface PainGraphProps {
  data: Treatment[];
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: any;
}

//-------------- Custom Tooltip --------------

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const d = payload[0].payload;
    const dateStr = d.startTime?.toFormat('dd/MM/yyyy HH:mm');
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          border: '1px solid #ccc',
          fontSize: '12px',
        }}
      >
        <p>Date: {dateStr}</p>
        {/* If you have 2 lines, each line’s data is in the payload array */}
        {payload.map((pl: any) => {
          const label = pl.dataKey === 'painScorePre' ? 'Pain Pre' : 'Pain Post';
          return (
            <p key={pl.dataKey}>
              {label}: {pl.value}
            </p>
          );
        })}
      </div>
    );
  }
  return null;
};

//-------------- Component --------------

const PainGraph: React.FC<PainGraphProps> = ({ data }) => {
  // For toggling line visibility: index 0 => Pre line, index 1 => Post line
  const [lineVisibility, setLineVisibility] = useState<boolean[]>([true, true]);

  const [filteredData, setFilteredData] = useState<Treatment[]>([]);

  // No table here, but feel free to add if needed
  const [daysShown, setDaysShown] = useState<number | 'all'>(7);
  const [currentStart, setCurrentStart] = useState<DateTime>(
    DateTime.now().minus({ days: 6 }).startOf('day')
  );
  const [currentEnd, setCurrentEnd] = useState<DateTime>(DateTime.now().endOf('day'));

  const timeRanges = [
    { label: 'Single Day', value: 1 },
    { label: '7 Days', value: 7 },
    { label: '1 Month', value: 28 },
    { label: '1 Year', value: 365 },
    { label: 'All Time', value: 'all' },
  ];

  //--- Toggle lines
  const toggleLineVisibility = (index: number) => {
    const newVis = [...lineVisibility];
    newVis[index] = !newVis[index];
    setLineVisibility(newVis);
  };

  //--- Shift Range: Previous
  const handlePrevious = () => {
    if (daysShown === 'all') return;
    const offset = daysShown === 1 ? { days: 1 } : { days: daysShown };
    setCurrentStart(currentStart.minus(offset));
    setCurrentEnd(currentEnd.minus(offset));
  };

  //--- Shift Range: Next
  const handleNext = () => {
    if (daysShown === 'all') return;
    const offset = daysShown === 1 ? { days: 1 } : { days: daysShown };
    setCurrentStart(currentStart.plus(offset));
    setCurrentEnd(currentEnd.plus(offset));
  };

  //--- Change Time Range
  const handleTimeRangeChange = (value: number | 'all') => {
    setDaysShown(value);

    if (value === 'all') {
      // If no data, fallback
      if (!data || data.length === 0) {
        setCurrentStart(DateTime.now().minus({ days: 1 }));
        setCurrentEnd(DateTime.now());
        return;
      }
      // Find earliest & latest
      const sortedByStart = [...data].sort(
        (a, b) => (a.startTime?.toMillis() ?? 0) - (b.startTime?.toMillis() ?? 0)
      );
      const earliest = sortedByStart[0].startTime;
      const latest = sortedByStart[sortedByStart.length - 1].startTime;
      if (earliest && latest) {
        setCurrentStart(earliest.startOf('day'));
        setCurrentEnd(latest.endOf('day'));
      }
    } else {
      // numeric
      const newStart =
        value === 1
          ? DateTime.now().startOf('day')
          : DateTime.now().minus({ days: value - 1 }).startOf('day');
      const newEnd = DateTime.now().endOf('day');
      setCurrentStart(newStart);
      setCurrentEnd(newEnd);
    }
  };

  //--- Filter data
  useEffect(() => {
    const filterData = (start: DateTime, end: DateTime) =>
      data.filter((item) => {
        if (!item.startTime) return false;
        return item.startTime >= start && item.startTime <= end;
      });

    setFilteredData(filterData(currentStart, currentEnd));
  }, [data, currentStart, currentEnd, daysShown]);

  //--- Transform for Recharts
  const transformedData = filteredData.map((item) => ({
    ...item,
    timeStamp: item.startTime ? item.startTime.toMillis() : 0,
    // Recharts lines: painScorePre, painScorePost
  }));

  //--- X Axis Ticks
  let ticks: number[] = [];
  if (daysShown === 'all') {
    // ~6 ticks from earliest->latest
    const totalMs = currentEnd.toMillis() - currentStart.toMillis();
    const segmentCount = 6;
    const segmentSize = totalMs / segmentCount;
    ticks = Array.from({ length: segmentCount + 1 }).map((_, i) =>
      currentStart.toMillis() + i * segmentSize
    );
  } else if (daysShown === 1) {
    // single day -> 24 hourly
    ticks = Array.from({ length: 24 }).map((_, i) =>
      currentStart.plus({ hours: i }).toMillis()
    );
  } else if (daysShown === 7) {
    // 7 days -> daily
    ticks = Array.from({ length: 7 }).map((_, i) =>
      currentStart.plus({ days: i }).toMillis()
    );
  } else if (daysShown === 28) {
    // 1 month -> 4 weekly
    ticks = Array.from({ length: 4 }).map((_, i) =>
      currentStart.plus({ weeks: i }).toMillis()
    );
  } else if (daysShown === 365) {
    // 1 year -> ~12 monthly
    const monthsCount = 12;
    ticks = Array.from({ length: monthsCount }).map((_, i) =>
      currentStart.plus({ months: i }).toMillis()
    );
  }

  //--- Format X Axis
  const formatTick = (tickMs: number) => {
    const dt = DateTime.fromMillis(tickMs);
    if (daysShown === 'all' || daysShown > 7) {
      return dt.toFormat('dd/MMM');
    } else if (daysShown === 7) {
      return dt.toFormat('EEE dd/MMM');
    } else {
      // single day
      return dt.toFormat('HH:mm');
    }
  };

  //--- Disable Next if we’re at or past today
  const isNextDisabled = daysShown === 'all' || currentEnd >= DateTime.now().endOf('day');

  return (
    <div className="card" style={{ height: '500px' }}>
      <h2 className="text-l font-semibold p-4 text-center">Pain</h2>

      {/* Date Range Controls */}
      <div className="flex justify-between items-center mb-4 p-4">
        <button className="btn" style={{ fontSize: '20px' }} onClick={handlePrevious}>
          ←
        </button>
        <h2>
          {currentStart.toFormat('dd/MM/yyyy')} - {currentEnd.toFormat('dd/MM/yyyy')}
        </h2>
        <button
          className="btn"
          style={{ fontSize: '20px' }}
          onClick={handleNext}
          disabled={isNextDisabled}
        >
          →
        </button>
        <div className="flex">
          <select
            className="dropdown-selector p-2 rounded border"
            value={daysShown}
            onChange={(e) => {
              const val = e.target.value === 'all' ? 'all' : Number(e.target.value);
              handleTimeRangeChange(val);
            }}
          >
            {timeRanges.map((range) => (
              <option key={range.value} value={range.value}>
                {range.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Chart Section */}
      <div style={{ height: '200px' }}>
        <ResponsiveContainer
          key={`${daysShown}-${currentStart.toMillis()}-${currentEnd.toMillis()}`}
        >
          <ComposedChart
            data={transformedData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <Tooltip content={<CustomTooltip />} />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              interval={0}
              ticks={ticks}
              tickFormatter={formatTick}
              dataKey="timeStamp"
              type="number"
              domain={[currentStart.toMillis(), currentEnd.toMillis()]}
              scale="time"
              style={{ fontSize: '12px' }}
            />
            <YAxis
              domain={[0, 10]}
              ticks={[0, 5, 10]}
              axisLine={{ stroke: '#AAAAAA' }}
              tickLine={{ stroke: '#AAAAAA' }}
              tick={{ fill: '#000000', fontSize: '12px' }}
            />

            {/* Pre-Pain Line */}
            {lineVisibility[0] && (
              <Line
                type="monotone"
                dataKey="painScorePre"
                stroke="#F60E38"
                strokeWidth={2}
                dot={{ r: 2 }}
                isAnimationActive={false}
              />
            )}

            {/* Post-Pain Line */}
            {lineVisibility[1] && (
              <Line
                type="monotone"
                dataKey="painScorePost"
                stroke="#FFA452"
                strokeWidth={2}
                dot={{ r: 2 }}
                isAnimationActive={false}
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>

      {/* Simple Legend / Toggle Buttons */}
      <ul className="menu menu-horizontal bg-base-100 rounded-box">
        <li>
          <a
            className="bg-base-100 btn"
            style={{ color: '#F60E38', opacity: lineVisibility[0] ? 1 : 0.2 }}
            onClick={() => toggleLineVisibility(0)}
          >
            Pain Pre
          </a>
        </li>
        <li>
          <a
            className="bg-base-100 btn"
            style={{ color: '#FFA452', opacity: lineVisibility[1] ? 1 : 0.2 }}
            onClick={() => toggleLineVisibility(1)}
          >
            Pain Post
          </a>
        </li>
      </ul>
    </div>
  );
};

export default PainGraph;
import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { createPortal } from "react-dom";

/**  Data Models **/
interface Treatment {
  id: string;
  userId: string;
  length: number;
}

interface LeaderboardUser {
  id: string;
  name: string;
  email: string;
  minutesOfTreatment: number; 
  amountOfAssessments: number;
  minutesOfPower: number;
  minutesOfFrequency: number;
}

const CACHE_DURATION = 10 * 60 * 1000; // 10 minutes in ms

const Leaderboard: React.FC = () => {
  const [users, setUsers] = useState<LeaderboardUser[]>([]);
  const [sortBy, setSortBy] = useState<string>("minutesOfTreatment");
  const [loading, setLoading] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState(false);
  const [lastFetched, setLastFetched] = useState<number>(0);

  /** UTILS: Sorting function helper **/
  const sortFn = (a: LeaderboardUser, b: LeaderboardUser) => {
    // @ts-ignore
    return b[sortBy] - a[sortBy];
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // 1) Fetch all users
        const userSnapshot = await getDocs(collection(db, "users"));
        const userArray: LeaderboardUser[] = [];
        userSnapshot.forEach((doc) => {
          const data = doc.data();
          userArray.push({
            id: doc.id,
            name: data.name || "Unknown",
            email: data.email || "No Email",
            minutesOfTreatment: 0,
            amountOfAssessments: 0,
            minutesOfPower: 0,
            minutesOfFrequency: 0,
          });
        });

        // 2) Fetch in parallel
        const [backSnap, armSnap, kneeSnap, tSnap, rSnap] = await Promise.all([
          getDocs(collection(db, "back_assessment")),
          getDocs(collection(db, "arm_assessment")),
          getDocs(collection(db, "knee_assessment")),
          getDocs(collection(db, "treatments")),
          getDocs(collection(db, "research_data")),
        ]);

        // 3) Treatments
        const tData: Treatment[] = [];
        tSnap.forEach((doc) => {
          const data = doc.data();
          tData.push({
            id: doc.id,
            userId: data.userId,
            length: data.length || 0,
          });
        });
        tData.forEach((treat) => {
          const matchedUser = userArray.find((u) => u.id === treat.userId);
          if (matchedUser) {
            matchedUser.minutesOfTreatment += treat.length;
          }
        });

        // 4) Assessments (back, arm, knee)
        backSnap.forEach((ass) => {
          const matchedUser = userArray.find((u) => u.id === ass.data().userId);
          if (matchedUser) {
            matchedUser.amountOfAssessments += 1;
          }
        });
        armSnap.forEach((ass) => {
          const matchedUser = userArray.find((u) => u.id === ass.data().userId);
          if (matchedUser) {
            matchedUser.amountOfAssessments += 1;
          }
        });
        kneeSnap.forEach((ass) => {
          const matchedUser = userArray.find((u) => u.id === ass.data().userId);
          if (matchedUser) {
            matchedUser.amountOfAssessments += 1;
          }
        });

        // 5) Research data (Power or Frequency)
        rSnap.forEach((res) => {
          const data = res.data();
          const matchedUser = userArray.find((u) => u.id === data.userId);
          if (matchedUser) {
            if (data.powerSingle) {
              matchedUser.minutesOfPower += data.length || 0;
            } else if (data.freqSingle) {
              matchedUser.minutesOfFrequency += data.length || 0;
            }
          }
        });

        // 6) Update state and set lastFetched
        setUsers(userArray);
        setLastFetched(Date.now());
      } catch (err) {
        console.error("Error fetching leaderboard data:", err);
      } finally {
        setLoading(false);
      }
    };

    // Check cache. If < 10 min old, skip re-fetch
    const now = Date.now();
    if (now - lastFetched < CACHE_DURATION && users.length > 0) {
      // Data is still fresh, do not re-fetch
      setLoading(false);
      return;
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastFetched, sortBy]);

  /** If data is still loading **/
  if (loading) {
    return <p className="text-center">Loading leaderboard...</p>;
  }

  /** Sort the users array by the chosen metric */
  const sortedUsers = [...users].sort((a, b) => {
    // @ts-ignore
    return b[sortBy] - a[sortBy];
  });

  return (
    <>
      {/* Trigger to open the modal */}
      <li>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(true);
          }}
        >
          Leaderboard
        </a>
      </li>

      {/* Leaderboard Modal */}
      {isOpen &&
        createPortal(
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="relative bg-white p-8 rounded-3xl shadow-2xl w-11/12 lg:w-3/4 max-h-[80vh] overflow-auto">
              {/* Close Button */}
              <button
                className="btn btn-sm btn-circle absolute right-4 top-4"
                onClick={() => setIsOpen(false)}
              >
                ✕
              </button>

              {/* Header */}
              <div className="mb-6">
                <h2 className="text-2xl font-bold text-gray-900">Leaderboard</h2>
              </div>

              {/* Sort Selector */}
              <div className="mb-4 flex items-center space-x-2">
                <label className="text-gray-700 font-medium">Sort by:</label>
                <select
                  className="border border-gray-300 rounded px-2 py-1 focus:outline-none"
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                >
                  <option value="minutesOfTreatment">Treatment</option>
                  <option value="amountOfAssessments">Assessments</option>
                  <option value="minutesOfPower">Research (Power)</option>
                  <option value="minutesOfFrequency">Research (Frequency)</option>
                </select>
              </div>

              {/* Leaderboard Table */}
              <table className="w-full border-collapse text-sm">
                <thead>
                  <tr className="border-b border-gray-200 bg-gray-100 text-gray-600 uppercase">
                    <th className="px-4 py-2 text-left w-12">Rank</th>
                    <th className="px-4 py-2 text-left">Name</th>
                    <th className="px-4 py-2 text-left">Email</th>
                    <th
                      className={`px-4 py-2 text-left ${
                        sortBy === 'minutesOfTreatment' ? 'bg-blue-50 font-semibold' : ''
                      }`}
                    >
                      Treatment
                    </th>
                    <th
                      className={`px-4 py-2 text-left ${
                        sortBy === 'amountOfAssessments' ? 'bg-blue-50 font-semibold' : ''
                      }`}
                    >
                      Assessments
                    </th>
                    <th
                      className={`px-4 py-2 text-left ${
                        sortBy === 'minutesOfPower' ? 'bg-blue-50 font-semibold' : ''
                      }`}
                    >
                      Power
                    </th>
                    <th
                      className={`px-4 py-2 text-left ${
                        sortBy === 'minutesOfFrequency' ? 'bg-blue-50 font-semibold' : ''
                      }`}
                    >
                      Frequency
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedUsers.map((user, index) => (
                    <tr key={user.id} className="border-b border-gray-100 hover:bg-gray-50">
                      <td className="px-4 py-2 text-gray-700">{index + 1}</td>
                      <td className="px-4 py-2 font-medium text-gray-700">{user.name}</td>
                      <td className="px-4 py-2 text-gray-600">{user.email}</td>
                      <td
                        className={`px-4 py-2 ${
                          sortBy === 'minutesOfTreatment' ? 'font-semibold text-blue-700' : ''
                        }`}
                      >
                        {user.minutesOfTreatment}
                      </td>
                      <td
                        className={`px-4 py-2 ${
                          sortBy === 'amountOfAssessments' ? 'font-semibold text-blue-700' : ''
                        }`}
                      >
                        {user.amountOfAssessments}
                      </td>
                      <td
                        className={`px-4 py-2 ${
                          sortBy === 'minutesOfPower' ? 'font-semibold text-blue-700' : ''
                        }`}
                      >
                        {user.minutesOfPower}
                      </td>
                      <td
                        className={`px-4 py-2 ${
                          sortBy === 'minutesOfFrequency' ? 'font-semibold text-blue-700' : ''
                        }`}
                      >
                        {user.minutesOfFrequency}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>,
          document.body
        )}
    </>
  );
};

export default Leaderboard;
import React, { useState } from 'react';
import CreatePlan from './createPlan';
import ViewPlan from './viewPlan';

import { useUserLevel, AdminLevel } from '../../admin';


const Plan: React.FC = ({  }) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const { currentUserLevel } = useUserLevel();

  const handleMenuClick = (option: string) => {
    setSelectedOption(option);
  };

  const handleCancel = () => {
    setSelectedOption(null);
  };

  return (
    <div>
      <div className="dropdown">
        <label tabIndex={0} className="btn mx-2 btn-inactive">Recovery Plans</label>
        <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 z-50">
          <li onClick={() => handleMenuClick('create')}>
            <a>Create Plan</a>
          </li>
          {currentUserLevel === AdminLevel.SUPER_ADMIN && (

          <li onClick={() => handleMenuClick('view')}>
            <a>View Plans</a>
          </li>)}
        </ul>
      </div>

      {selectedOption === 'create' && (
        <CreatePlan onClose={handleCancel} />
      )}


      {selectedOption === 'view' && (
        <ViewPlan onClose={handleCancel} />
      )}
   
    </div>
  );
};

export default Plan;
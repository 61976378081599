import React, { useState } from "react";
import { createPortal } from "react-dom";

const featureGroups = [
  ["Frequency", "Power", "IMU", "Treatment"], // Group 1
  ["Back Assessment", "Knee Assessment", "Arm Assessment", "Analytics"], // Group 2
  ["Research Data", "Schedule", "Manufacture", "Wearables"], // Group 3
];

const AccessCodes: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);
  const [generatedCode, setGeneratedCode] = useState<string | null>(null);

  // Toggle feature selection
  const toggleFeature = (feature: string) => {
    setSelectedFeatures((prev) =>
      prev.includes(feature)
        ? prev.filter((f) => f !== feature)
        : [...prev, feature]
    );
  };

  // Generate code based on selected features
  const generateCode = () => {
    const hexDigits = featureGroups.map((group) => {
      const binaryString = group
        .map((feature) => (selectedFeatures.includes(feature) ? "1" : "0"))
        .join(""); // Convert selected features to binary string (4 bits)

      return parseInt(binaryString, 2).toString(16).toUpperCase(); // Convert binary to hex
    });

    setGeneratedCode(`MVR${hexDigits.join("")}0`); // Combine hex digits
  };

  return (
    <>
      {/* Clickable List Item */}
      <li>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(true);
          }}
        >
          Access Codes
        </a>
      </li>

      {/* Modal Portal */}
      {isOpen &&
        createPortal(
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white h-5/6 w-11/12 lg:w-3/4 xl:w-5/6 p-8 rounded-3xl shadow-2xl relative overflow-auto">
              {/* Close Button */}
              <button
                className="btn btn-sm btn-circle absolute right-2 top-2"
                onClick={() => setIsOpen(false)}
              >
                ✕
              </button>

              {/* Title */}
              <h2 className="text-2xl font-semibold mb-4">Access Codes</h2>
              <div className="bg-blue-50 border border-blue-300 text-blue-700 p-3 rounded-md mb-4">
              <p className="text-sm">
                The access codes required for user signup for each app.
              </p>
            </div>
              {/* Predefined Access Codes */}
              <div className="space-y-4">
                <div className="p-4 bg-gray-100 rounded-lg shadow">
                  <h3 className="text-lg font-medium">Back Recover</h3>
                  <p className="text-gray-600">
                    Code: <span className="font-mono font-bold">backrecover24</span>
                  </p>
                </div>

                <div className="p-4 bg-gray-100 rounded-lg shadow">
                  <h3 className="text-lg font-medium">Myovolt Research</h3>
                  <p className="text-gray-600">
                    Code: <span className="font-mono font-bold">myovoltresearch24</span>
                  </p>
                </div>

                {/* <div className="p-4 bg-gray-100 rounded-lg shadow">
                  <h3 className="text-lg font-medium">Myovolt Research</h3>
                  <p className="text-gray-600">
                    Default Code: <span className="font-mono font-bold">MVRE080</span>
                  </p>
                  <h3 className="text-sm font-small">
                    Frequency, Power, IMU and Research Data
                </h3>
                  
              <div className="mt-6">
                <h3 className="text-lg font-semibold">Select Features</h3>
                <h3 className="text-sm font-small">
                Custom features remain valid until the user joins a group, which modifies them. Leaving group sets default features.
                </h3>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-3 mt-3">
                  {featureGroups.flat().map((feature) => (
                    <label key={feature} className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={selectedFeatures.includes(feature)}
                        onChange={() => toggleFeature(feature)}
                        className="h-4 w-4"
                      />
                      <span>{feature}</span>
                    </label>
                  ))}
                </div>
              </div>

              <button
                onClick={generateCode}
                className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700"
              >
                Generate Code
              </button>

              {generatedCode && (
                <div className="mt-4 p-4 bg-gray-200 rounded-lg text-lg font-mono font-bold text-center">
                  {generatedCode}
                </div>
              )}
                </div> */}
              </div>
         
             
            </div>
          </div>,
          document.body // Renders the modal at the root level
        )}
    </>
  );
};

export default AccessCodes;
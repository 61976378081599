import React from "react";
import { createContext, useContext, useEffect, useState, useRef } from "react";
import { Tooltip } from 'react-tooltip';
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { scaleLinear } from "d3-scale";
import { collection, getDocs, query, where, doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import 'react-tooltip/dist/react-tooltip.css';


interface geoDataItem {
  country: string;  // ISO Alpha-2 code (e.g., 'US', 'DE') compatible with react-simple-maps
  treatmentTotal: number;
}

// Helper function to extract country code from locale string (e.g., en_US -> US)
const extractCountryCode = (locale: string): string | null => {
  const parts = locale.split('_');
  return parts.length > 1 ? parts[1] : null;  // Extract the country code if it exists
};

const aGlobalTreatments = async (): Promise<{ geoData: geoDataItem[], totalTreatments: number }> => {
  const treatmentsCollection = collection(db, 'treatments');
  const querySnapshot = await getDocs(treatmentsCollection);

  const geoData: geoDataItem[] = [];
  let totalTreatments = 0;

  querySnapshot.forEach((docSnapshot) => {
    const data = docSnapshot.data();
    if (data && data.locale && typeof data.length === 'number') {
      const countryCode = extractCountryCode(data.locale);  // Get the country code

      if (countryCode) {
        // Check if the country already exists in the geoData array
        const existingGeoDataItem = geoData.find(item => item.country === countryCode);

        if (existingGeoDataItem) {
          // If the country exists, add the treatment length to the treatmentTotal
          existingGeoDataItem.treatmentTotal += data.length;
        } else {
          // If the country doesn't exist, create a new geoDataItem with the treatment length
          geoData.push({
            country: countryCode,  // Use the extracted country code
            treatmentTotal: data.length
          });
        }
      }

      // Add the treatment length to the global total treatment minutes
      totalTreatments += data.length;
    }
  });

  // Return both geoData and the total number of treatment minutes globally
  return { geoData, totalTreatments };
};

const aGlobalResearchTotal = async () => {
  const researchCollection = collection(db, 'research_data');
  const researchQuerySnapshot = await getDocs(researchCollection);
  let l = 0;

  researchQuerySnapshot.forEach(async (docSnapshot) => {
    const data = docSnapshot.data();
    if (data.length > 0) {
      l += data.length;
    }
  })
  return l;
};

const aGlobalRecoveryTotal = async (): Promise<number> => {
  const researchCollection = collection(db, 'metrics');
  const researchQuerySnapshot = await getDocs(researchCollection);
  let totalRecovery = 0;
  let index = 0;

  researchQuerySnapshot.forEach((docSnapshot) => {
    const data = docSnapshot.data();
    if (data.recovery !== null) {
      totalRecovery += data.recovery;
      index++;
    }
  });
  totalRecovery /= index;
  // Return the total recovery rounded to 2 decimal places
  return parseFloat(totalRecovery.toFixed(2));
};

const geoUrl = "https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_countries_lakes.geojson";

interface WorldTreatmentsProps {
  onClose: () => void;
}

const WorldTreatments: React.FC<WorldTreatmentsProps> = ({ onClose }) => {
  const [researchTotal, setResearchTotal] = useState<number>();
  const [recoveryTotal, setRecoveryTotal] = useState<number>();
  const [globalTreatments, setGlobalTreatments] = useState<geoDataItem[]>([]);
  const [totalTreatments, setTotalTreatments] = useState<number>(0);
  const [maxTreatments, setMaxTreatments] = useState<number>(1);
  const [tooltipContent, setTooltipContent] = useState<string | null>(null);  // State for tooltip content

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Call the async function
        const r = await aGlobalResearchTotal();
        setResearchTotal(r);

        const y = await aGlobalRecoveryTotal();
        setRecoveryTotal(y);

        const { geoData, totalTreatments } = await aGlobalTreatments();  // Destructure returned values
        setGlobalTreatments(geoData);  // Update the geoData state
        setTotalTreatments(totalTreatments);  // Update the total treatments state

        // Find the maximum number of treatments for color scaling
        const maxTreatments = Math.max(...geoData.map(item => item.treatmentTotal || 0));
        setMaxTreatments(maxTreatments);

      } catch (error) {
      }
    };

    // Call the async function
    fetchData();
  }, []); // The dependency array determines when the effect runs

  // Create a color scale based on the max treatment count
  const colorScale = scaleLinear<string>()
    .domain([1, maxTreatments]) // Scale from 0 to the maximum treatment count
    .range(['#EFDFFF', '#9747ff']); // Color range for the map, light to dark


  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white h-5/6 p-4 rounded-lg shadow-lg relative">
        
        {/* Tooltip Component */}
        {tooltipContent && (
          <Tooltip
            place="top"
            id="country-tooltip" // Unique ID for the tooltip
            content={tooltipContent} // Dynamically set content
          />
        )}
        {/* Header Section */}
        <div className="flex items-center justify-between w-full mb-4">
          
        

          {/* Centered Title */}
          <h2 className="text-l font-semibold text-center">World Treatments</h2>
          <h3 className="text-m text-center">{tooltipContent}</h3>

          {/* Close Button */}
          <button
            className="btn btn-sm btn-circle"
            onClick={onClose}
          >
            ✕
          </button>
        </div>

        <div className="w-full h-full">
        <div className="bg-blue-50 border border-blue-300 text-blue-700 p-3 rounded-md mb-4">
          <p className="text-sm">
            <span className="text-purple-600 font-semibold">{totalTreatments}</span> total minutes of Myovolt treatment administered via the Treatment feature.
          </p>
          <p className="text-sm">
            <span className="text-teal-600 font-semibold">{researchTotal}</span> total minutes of motor activation via the Frequency or Power features.
          </p>
          <p className="text-sm">
            Total combined recovery score of <span className="text-green-600 font-bold">{recoveryTotal}</span> via the Treatment and Assessment features.
          </p>
        </div>
          {/* The Map */}
          <ComposableMap
            projectionConfig={{
              scale: 200,  // Adjust zoom level as needed
              center: [0, 0], // Center the map
            }}
            width={1000}  // Adjusted to fit inside the white box
            height={800}  // Adjusted to fit inside the white box
            style={{ width: "100%", height: "100%" }}  // Make the map responsive
          >
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies
                  .filter((geo) => geo.properties.continent !== "Antarctica") // Filter out Antarctica
                  .map((geo) => {
                    const countryCode = geo.properties.iso_a2;
                    const countryData = globalTreatments.find(item => item.country === countryCode);  // Find the corresponding country in geoData
                    const treatmentTotal = countryData ? countryData.treatmentTotal || 0 : 0;
                    const fillColor = treatmentTotal === 0
                      ? '#EFEFEF' // Very light color for zero treatments
                      : colorScale(treatmentTotal); // Scale based on treatment count for non-zero

                    return (

                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        fill={fillColor} // Color based on treatment total
                        stroke="#FFF"
                        strokeWidth={0.5}
                        onMouseDownCapture={() => {
                          setTooltipContent(`${geo.properties.name}: ${treatmentTotal} minutes`);
                        }}
                        onMouseEnter={() => {
                          //setTooltipContent(`${geo.properties.name}: ${treatmentTotal} minutes`);
                        }}
                        onMouseLeave={() => {
                          //setTooltipContent(`${geo.properties.name}: ${treatmentTotal} minutes`);
                        }}
                        style={{
                          default: { outline: 'none' },
                          hover: { fill: '#6A6AAA', outline: 'none' }, // Hover effect
                          pressed: { fill: '#5A5AAA', outline: 'none' }, // Pressed effect
                        }}
                      />
                    );
                  })
              }
            </Geographies>
          </ComposableMap>

        </div>
      </div>
    </div>
  );
};

export default WorldTreatments;
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { collection, getDocs, doc, updateDoc, addDoc } from "firebase/firestore";
import { db } from "../../firebase";

interface User {
  id: string;
  name: string;
  email: string;
  groupId: string | null;
  access: Record<string, boolean>;
}

interface Group {
  groupId: string;
  recoveryPlan: string;
  password?: string;
  Admin: string;
  Members: string[];
  access: Record<string, boolean>;
}

const defaultFeatureList: Record<string, boolean> = {
  "Analytics": false,
  "Arm Assessment": false,
  "Back Assessment": false,
  "Frequency": true,
  "IMU": true,
  "Knee Assessment": false,
  "Manufacture": false,
  "Power": true,
  "Research Data": true,
  "Schedule": false,
  "Treatment": false,
  "Wearables": false,
};

const NewGroup2: React.FC<{ onClose: () => void, email: string, userId: string }> = ({ onClose, email, userId }) => {
  const [groupName, setGroupName] = useState('');
  const [recoveryPlan, setRecoveryPlan] = useState('');
  const [recoveryPlans, setRecoveryPlans] = useState<string[]>([]);
  const [password, setPassword] = useState('');
  const [featureList, setFeatureList] = useState<Record<string, boolean>>(defaultFeatureList);
  const [groups, setGroups] = useState<Group[]>([]);

  // Fetch recovery plans on mount
  useEffect(() => {
    const fetchRecoveryPlans = async () => {
      try {
        const [usersSnapshot, recoveryPlansSnapshot, groupsSnapshot] = await Promise.all([
          getDocs(collection(db, 'users')),
          getDocs(collection(db, 'recoveryPlans')),
          getDocs(collection(db, 'groups')),
        ]);
    
        // Extract recovery plan IDs
        const plans = recoveryPlansSnapshot.docs.map(doc => doc.id);
    
        // Extract group data
        const groupsData: Group[] = groupsSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            groupId: data.groupId,
            recoveryPlan: data.recoveryPlan,
            Admin: data.Admin,
            Members: data.Members || [],
            password: data.password || '',
            access: data.access || defaultFeatureList,
          };
        });
    
        setGroups(groupsData);
        setRecoveryPlans(plans);
      } catch (error) {
        console.error('Error fetching recovery plans:', error);
      }
    };

    fetchRecoveryPlans();
  }, []);

  // Handle feature toggle
  const handleFeatureToggle = useCallback((feature: string) => {
    setFeatureList(prev => ({
      ...prev,
      [feature]: !prev[feature],
    }));
  }, []);

  // Handle creating a new group
  const handleCreateGroup = useCallback(async () => {
    if (!groupName || !recoveryPlan) {
      alert('Please fill in all required fields.');
      return;
    }

    for (const g of groups) {
      if (groupName === g.groupId) {
        alert('Group already exists.');
        return;
      }
    }

    try {
      const newGroup: Group = {
        groupId: groupName,
        recoveryPlan,
        password,
        Admin: email,
        Members: [email], // Add the admin as the first member
        access: featureList,
      };

      // Create the group in Firestore
      const groupRef = await addDoc(collection(db, 'groups'), newGroup);

      // Update the admin's user information
      const userDoc = doc(db, 'users', userId);
      await updateDoc(userDoc, {
        groupId: groupName,
        recoveryPlan,
        access: featureList,
      });

      onClose();
      window.location.reload();
      alert('Group created');
    } catch (error) {
      console.error('Error creating group:', error);
      alert('An error occurred while creating the group. Please try again.');
    }
  }, [groupName, recoveryPlan, password, email, userId, featureList, onClose]);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white h-5/6 w-11/12 lg:w-3/4 xl:w-2/3 p-8 rounded-3xl shadow-2xl relative overflow-auto">
        <button className="btn btn-sm btn-circle absolute right-2 top-2" onClick={onClose}>✕</button>

        <h2 className="text-3xl font-semibold text-gray-800 mb-6">Create a New Group</h2>
      
        <div className="mb-6">
          <label className="block text-lg font-medium text-gray-700 mb-2">Group Name</label>
          <div className="bg-blue-50 border border-blue-300 text-blue-700 p-3 rounded-md mb-4">
            <p className="text-sm">
              The group's name must be unique as it is used to identify the group.
            </p>
          </div>
          <input
            type="text"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            className="select select-bordered w-full"
            placeholder="Enter group name"
          />
        </div>

        <div className="mb-6">
          <label className="block text-lg font-medium text-gray-700 mb-2">Recovery Plan</label>
          <div className="bg-blue-50 border border-blue-300 text-blue-700 p-3 rounded-md mb-4">
            <p className="text-sm">
              The recovery plan that will be assigned to all members of the group.
            </p>
          </div>
          <select
            value={recoveryPlan}
            onChange={(e) => setRecoveryPlan(e.target.value)}
            className="select select-bordered w-full"
          >
            <option value="" disabled>Select a recovery plan</option>
            {recoveryPlans.map(plan => (
              <option key={plan} value={plan}>{plan}</option>
            ))}
          </select>
        </div>

        <div className="mb-6">
          <label className="block text-lg font-medium text-gray-700 mb-2">Group Password</label>
          <div className="bg-blue-50 border border-blue-300 text-blue-700 p-3 rounded-md mb-4">
            <p className="text-sm">
              The code that must be entered alongside the group's name when a user wants to join the group via app.
            </p>
          </div>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="select select-bordered w-full"
            placeholder="Enter group password"
          />
        </div>

        {/* Feature Access Section */}
        <div className="mb-6">
          <h3 className="text-xl font-medium text-gray-800 mb-4">Feature Access</h3>
          <div className="bg-blue-50 border border-blue-300 text-blue-700 p-3 rounded-md mb-4">
            <p className="text-sm">
              The user’s feature access will be ignored and instead determined by the group’s features list while they are members.
            </p>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {Object.entries(featureList).map(([feature, enabled]) => (
              <label key={feature} className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={enabled}
                  onChange={() => handleFeatureToggle(feature)}
                  className="checkbox checkbox-primary"
                />
                {feature}
              </label>
            ))}
          </div>
        </div>
        <div className="bg-blue-50 border border-blue-300 text-blue-700 p-3 rounded-md mb-4">
        <p className="text-sm">
          You will be assigned as the group admin.
            </p>
            <p className="text-sm">
              The group must have one admin assigned. Admins act as members but with more control over parameters of the group via dashboard.
            </p>
          
          </div>
        <label className="block text-lg font-medium text-gray-700 mb-2">Admin: {email}</label>

        <div className="text-right">
          <button
            onClick={handleCreateGroup}
            className="btn mx-2 p-2"
          >
            Create Group
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewGroup2;
import React, { useState, useEffect } from 'react';
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Import Firestore instance from your firebase configuration

interface Phase {
  pattern: number;
  intensity: number;
  length: number;
  enabled: boolean;
}

interface CreateSequenceProps {
  onClose: () => void;
  editingSequence?: any;
}

const CreateSequence: React.FC<CreateSequenceProps> = ({ onClose, editingSequence }) => {
  const [productList] = useState<string[]>([
    'Back Recover 6 Motor (VM6MOAABLE)',
    'Back Recover 4 Motor (VM4MOAABLE)',
    '2 Motor (VM2MOBABLE)',
    '1 Motor (VM1MOAABLE)',
  ]);
  const [product, setProduct] = useState<string>('');
  const [productCode, setProductCode] = useState<string>('');
  const [index, setIndex] = useState<number | null>(null);
  const [phases, setPhases] = useState<Phase[]>(Array(5).fill({ pattern: 1, intensity: 1, length: 1, enabled: true }));
  const [repeat, setRepeat] = useState<number>(0); // Initialize repeat to 0
  const [repeatEnabled, setRepeatEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (editingSequence) {
      const { productCode, sequence } = editingSequence;
      setProduct(sequence.product);
      setProductCode(productCode);
      setIndex(sequence.index);
      setRepeat(sequence.repeat);
      setRepeatEnabled(sequence.repeat > 0);

      const newPhases = ['phase1', 'phase2', 'phase3', 'phase4', 'phase5'].map((key, idx) => ({
        pattern: sequence[key].pattern,
        intensity: sequence[key].intensity,
        length: sequence[key].length,
        enabled: sequence[key].pattern > 0 || sequence[key].intensity > 0 || sequence[key].length > 0,
      }));

      setPhases(newPhases);
    } else if (product) {
      const codeMatch = product.match(/\((VM\dMOAABLE)\)/);
      const productCode = codeMatch ? codeMatch[1] : '';
      setProductCode(productCode);

      const fetchIndex = async () => {
        const sequencesRef = doc(db, 'sequences', productCode);
        const snapshot = await getDoc(sequencesRef);
        if (snapshot.exists()) {
          const sequences = snapshot.data();
          const currentIndexes = Object.keys(sequences)
            .filter(key => key.startsWith('sequence'))
            .map(key => sequences[key].index);
          const nextIndex = Math.max(...currentIndexes, 0) + 1;
          setIndex(nextIndex);
        } else {
          setIndex(1);
        }
      };

      fetchIndex();
    }
  }, [product, editingSequence]);

  const handlePhaseChange = (phaseIndex: number, field: keyof Phase, value: number | boolean) => {
    const newPhases = [...phases];
    newPhases[phaseIndex] = { ...newPhases[phaseIndex], [field]: value };
    setPhases(newPhases);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (product && index !== null && productCode) {
      const sequencesRef = doc(db, 'sequences', productCode);
      const snapshot = await getDoc(sequencesRef);
      const newSequenceKey = `sequence${index}`;
      const newSequence = {
        [newSequenceKey]: {
          index,
          phase1: phases[0].enabled ? phases[0] : { pattern: 0, intensity: 0, length: 0 },
          phase2: phases[1].enabled ? phases[1] : { pattern: 0, intensity: 0, length: 0 },
          phase3: phases[2].enabled ? phases[2] : { pattern: 0, intensity: 0, length: 0 },
          phase4: phases[3].enabled ? phases[3] : { pattern: 0, intensity: 0, length: 0 },
          phase5: phases[4].enabled ? phases[4] : { pattern: 0, intensity: 0, length: 0 },
          product,
          productCode,
          repeat: repeatEnabled ? repeat : 0,
        },
      };

      if (snapshot.exists()) {
        await setDoc(sequencesRef, newSequence, { merge: true });
      } else {
        await setDoc(sequencesRef, {
          product,
          productCode,
          ...newSequence
        });
      }

      console.log('Sequence created:', newSequence);
      onClose(); // Close the modal after successful submission
    }
  };

  const getPatternOptions = () => {
    var maxPattern = 1;
    switch(product){
      case 'Back Recover 6 Motor (VM6MOAABLE)': maxPattern = 7;
      break;
      case 'Back Recover 4 Motor (VM4MOAABLE)': maxPattern = 5;
      break;
      case '2 Motor (VM2MOBABLE)': maxPattern = 4;
      break;
      case '1 Motor (VM1MOAABLE)': maxPattern = 1;
      break;
      default: maxPattern = 1;
      break;
    }
    return Array.from({ length: maxPattern }, (_, i) => i + 1);
  };

  const isFormValid = () => {
    if (!product || index === null) return false;
    if (repeatEnabled && (repeat === null || repeat < 1 || repeat > 5)) return false;
    for (const phase of phases) {
      if (phase.enabled) {
        if (phase.intensity < 1 || phase.intensity > 100) return false;
        if (phase.length < 1 || phase.length > 30) return false;
      }
    }
    return true;
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white h-5/6 w-11/12 lg:w-3/4 xl:w-2/3 p-8 rounded-3xl shadow-2xl relative overflow-auto">
        <button className="btn btn-sm btn-circle absolute right-2 top-2" onClick={onClose}>✕</button>
  
        <h2 className="text-3xl font-semibold text-gray-800 mb-6">Create Sequence</h2>
  
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Product Selection */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Product</label>
            <div className="bg-blue-50 border border-blue-300 text-blue-700 p-3 rounded-md mb-4">
              <p className="text-sm">
                The Myovolt product that this sequence will be designed for.
              </p>
            </div>
            <select
              value={product}
              onChange={(e) => setProduct(e.target.value)}
              className="select select-bordered w-full"
              required
            >
              <option value="" disabled>Select product</option>
              {productList.map((prod) => (
                <option key={prod} value={prod}>{prod}</option>
              ))}
            </select>
          </div>
  
          {/* Index Display */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Index</label>
            <div className="bg-blue-50 border border-blue-300 text-blue-700 p-3 rounded-md mb-4">
              <p className="text-sm">
                The unique ID assigned to the sequence.
              </p>
            </div>
            <input
              type="number"
              value={index || ''}
              readOnly
              className="input input-bordered w-full"
              required
            />
          </div>
          <div className="bg-blue-50 border border-blue-300 text-blue-700 p-3 rounded-md mb-4">
              <p className="text-sm">
                Each sequence consists of up to 5 phases and an option to repeat them.
              </p>
              <p className="text-sm">
                Each phase consists of a motor pattern (built into the device), a length of time that it will play for and an intensity factor (from 1% to 100%) that adjusts the motor power.
              </p>
              <p className="text-sm">
                A minimum of one phase must be assigned.
              </p>
            </div>
          {/* Phases */}
          {phases.map((phase, i) => (
            <div key={i} className={`space-y-4 ${i === 0 ? '' : 'mt-6'}`}>
              <div className="flex items-center space-x-4">
                <input
                  type="checkbox"
                  checked={phase.enabled}
                  onChange={(e) => handlePhaseChange(i, 'enabled', e.target.checked)}
                  disabled={i === 0}
                  className="checkbox checkbox-primary"
                />
                <h3 className="text-lg font-medium">Phase {i + 1}</h3>
              </div>
              <div className="grid grid-cols-3 gap-4">
                {/* Vibration Pattern */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Vibration Pattern</label>
                  <select
                    value={phase.pattern}
                    onChange={(e) => handlePhaseChange(i, 'pattern', Number(e.target.value))}
                    className="select select-bordered w-full"
                    disabled={!phase.enabled}
                    required
                  >
                    {getPatternOptions().map(option => (
                      <option key={option} value={option}>{option}</option>
                    ))}
                  </select>
                </div>
  
                {/* Motor Power */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Motor Power</label>
                  <input
                    type="number"
                    value={phase.intensity}
                    onChange={(e) => handlePhaseChange(i, 'intensity', Number(e.target.value))}
                    className="input input-bordered w-full"
                    min="1"
                    max="100"
                    disabled={!phase.enabled}
                    required
                  />
                </div>
  
                {/* Length */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Length (minutes)</label>
                  <input
                    type="number"
                    value={phase.length}
                    onChange={(e) => handlePhaseChange(i, 'length', Number(e.target.value))}
                    className="input input-bordered w-full"
                    min="1"
                    max="30"
                    disabled={!phase.enabled}
                    required
                  />
                </div>
              </div>
            </div>
          ))}
  
          {/* Repeat Sequence */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 flex items-center space-x-2 mb-2">
              <input
                type="checkbox"
                checked={repeatEnabled}
                onChange={(e) => setRepeatEnabled(e.target.checked)}
                className="checkbox checkbox-primary"
              />
              <span>Sequence Repeats</span>
            </label>
            {repeatEnabled && (
              <input
                type="number"
                value={repeat || 0} // Default to 0 if repeat is null
                onChange={(e) => setRepeat(Number(e.target.value))}
                className="input input-bordered w-full"
                min="1"
                max="5"
                required
              />
            )}
          </div>
  
          {/* Submit Button */}
          <button type="submit" className="btn btn-primary mx-2 p-2 w-full" disabled={!isFormValid()}>
            Create Sequence
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateSequence;
import React, { useEffect, useState } from "react";
import { DocumentData, Timestamp, collection, getDocs, query, doc, getDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebase";

/**
 * Safely convert a Firestore Timestamp or numeric epoch to a user-friendly date string.
 */
function formatDate(dateVal?: Timestamp | number): string {
  if (!dateVal) return "No date set";

  // If it's a Firestore Timestamp
  if (dateVal instanceof Timestamp) {
    return dateVal.toDate().toLocaleDateString();
  }

  // If it's a numeric epoch (milliseconds since 1970)
  if (typeof dateVal === "number") {
    return new Date(dateVal).toLocaleDateString();
  }

  return "Unknown format";
}

interface ViewAllPlansProps {
  onClose: () => void;
}

const dayNamesTitle = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const dayNames = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
const itemNames = ["Treatment", "Back Assessment", "Knee Assessment", "Arm Assessment"];

// 1) Define a structure for a single 'Plan' object, which can hold extra info
interface Plan {
  name: string;
  date?: Timestamp;
  weeks: string[][][]; // the existing 3D array for weeks->days->items
}

const getDay = (data: DocumentData, day: string, week: string): string[] | undefined => {
  if (data[week] && data[week][day] !== undefined && data[week][day] !== null) {
    const rawItems = data[week][day];
    const items: string[] = [];

    Object.keys(rawItems).forEach((key) => {
      itemNames.forEach((name) => {
        if (key.includes(name)) {
          items.push(name);
        }
      });
    });

    return items.length > 0 ? items : undefined;
  }
  return undefined;
};

const getWeek = (data: DocumentData, weekNumber: number): string[][] | undefined => {
  const week: string[][] = [];
  let hasValidDays = false;

  dayNames.forEach((dayName) => {
    const day = getDay(data, dayName, `week${weekNumber}`);
    if (day) {
      hasValidDays = true;
      week.push(day);
    } else {
      week.push([]);
    }
  });

  return hasValidDays ? week : undefined;
};

// This returns the 3D array of weeks, days, items
const getPlanWeeks = (data: DocumentData): string[][][] => {
  const weeks: string[][][] = [];
  for (let i = 1; i <= 12; i++) {
    const week = getWeek(data, i);
    if (week) {
      weeks.push(week);
    }
  }
  return weeks;
};

// 2) Create a function that returns a Plan object, including name/date
function buildPlanObject(data: DocumentData): Plan {
  return {
    name: data["recoveryPlan"] || "Not specified", // or doc.id, whichever you prefer
    date: data["date"] || "Not specified", // example extra attribute
    weeks: getPlanWeeks(data)
  };
}

const ViewAllPlans: React.FC<ViewAllPlansProps> = ({ onClose }) => {
  const [selectedPlanIndex, setSelectedPlanIndex] = useState<number | null>(null);

  // 3) Instead of string[][][][], store an array of Plan objects
  const [recoveryPlans, setRecoveryPlans] = useState<Plan[]>([]);

  // NEW STATE: searchQuery to filter the plan list
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchPlans = async () => {
      const qSnap = await getDocs(query(collection(db, "recoveryPlans")));
      const plans: Plan[] = [];

      qSnap.forEach((doc) => {
        const data = doc.data();
        // build a Plan object with name/date/weeks
        const planObj = buildPlanObject(data);
        plans.push(planObj);
      });

      setRecoveryPlans(plans);
    };

    fetchPlans();
  }, []);

  // Filtered list of plans based on searchQuery (case-insensitive)
  const filteredPlans = recoveryPlans.filter((plan) => {
    return plan.name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const handleDelete = async (recoveryPlan: string) => {
    const planDoc = doc(db, 'recoveryPlans', recoveryPlan);
    const snapshot = await getDoc(planDoc);

    if(snapshot.exists()){
      const confirmed = window.confirm('Are you sure you want to delete this Recovery Plan?');
      if(confirmed){
        deleteDoc(planDoc)
        onClose();
      }
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white h-5/6 w-11/12 lg:w-3/4 xl:w-2/3 p-8 rounded-3xl shadow-2xl relative overflow-auto">
        <button className="btn btn-sm btn-circle absolute right-2 top-2" onClick={onClose}>✕</button>
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-900">Recovery Plans</h2>
        </div>

        {/* Search Box */}
        {selectedPlanIndex === null && (
          <div className="mb-4">
            <input
              type="text"
              placeholder="Search by plan name..."
              className="border border-gray-300 rounded px-2 py-1 w-full"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        )}

        {!recoveryPlans.length ? (
          <p>Loading...</p>
        ) : selectedPlanIndex === null ? (
          <div className="space-y-4">
            {filteredPlans.map((plan, index) => (
              <button
                key={index}
                onClick={() => setSelectedPlanIndex(index)}
                className="btn btn-primary mx-2 p-2 w-full"
              >
                {plan.name}
              </button>
            ))}
          </div>
        ) : (
          <div>
            <h3 className="text-lg font-semibold text-gray-900 mb-4">
              {recoveryPlans[selectedPlanIndex].name}
              </h3>
            <p className="italic text-sm mb-4">
              Date created: {formatDate(recoveryPlans[selectedPlanIndex].date)}
            </p>
            <div className="space-y-6">
              {recoveryPlans[selectedPlanIndex].weeks.map((week, weekIndex) => (
                <div key={weekIndex} className="p-4 border rounded-lg bg-gray-100">
                  <h4 className="text-md font-semibold text-gray-800 mb-2">Week {weekIndex + 1}</h4>
                  <div className="grid grid-cols-7 gap-4 bg-white p-4 rounded-lg shadow-sm">
                    {week.map((day, dayIndex) => (
                      <div
                        key={dayIndex}
                        className="text-center border p-3 rounded-md bg-gray-50"
                      >
                        <h5 className="font-medium text-gray-700">{dayNamesTitle[dayIndex]}</h5>
                        {day.length > 0 ? (
                          <ul className="text-sm text-gray-600 mt-2">
                            {day.map((item, itemIndex) => (
                              <li
                                key={itemIndex}
                                className="bg-blue-100 text-blue-700 px-2 py-1 rounded-md mt-1"
                              >
                                {item}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p className="text-gray-400 mt-2">No items</p>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              <button
                        onClick={() => handleDelete(recoveryPlans[selectedPlanIndex].name)}
                        className="btn btn-secondary btn-sm w-28"
                      >
                        Delete
                      </button>
            </div>

            <button
              onClick={() => setSelectedPlanIndex(null)}
              className="mt-4 px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
            >
              Back to Plans
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewAllPlans;

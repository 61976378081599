import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { collection, getDocs, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useUserLevel, AdminLevel } from '../../admin';

interface User {
  id: string;
  name: string;
  email: string;
  groupId: string | null;
  access: Record<string, boolean>;
}

interface Group {
  id: string;
  groupId: string;
  recoveryPlan: string;
  Admin: string;
  Members: string[];
  password?: string;
  access: Record<string, boolean>;
}

const defaultFeatureList: Record<string, boolean> = {
  "Analytics": false,
  "Arm Assessment": false,
  "Back Assessment": false,
  "Frequency": true,
  "IMU": true,
  "Knee Assessment": false,
  "Manufacture": false,
  "Power": true,
  "Research Data": true,
  "Schedule": false,
  "Treatment": false,
  "Wearables": false,
};

interface ModifyGroupProps {
  onClose: () => void;
  groupId: string | null | undefined;
}

const ModifyGroup: React.FC<ModifyGroupProps> = ({ onClose, groupId }) => {
  const { currentUserLevel } = useUserLevel();
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [groupName, setGroupName] = useState('');
  const [recoveryPlan, setRecoveryPlan] = useState('');
  const [groupPassword, setGroupPassword] = useState('');
  const [recoveryPlans, setRecoveryPlans] = useState<string[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [adminUser, setAdminUser] = useState<User | null>(null);
  const [groups, setGroups] = useState<Group[]>([]);
  const [featureList, setFeatureList] = useState<Record<string, boolean>>(defaultFeatureList);

  // Fetch users, recovery plans, and groups on mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [usersSnapshot, recoveryPlansSnapshot, groupsSnapshot] = await Promise.all([
          getDocs(collection(db, 'users')),
          getDocs(collection(db, 'recoveryPlans')),
          getDocs(collection(db, 'groups')),
        ]);

        const usersData: User[] = usersSnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().name,
          email: doc.data().email,
          groupId: doc.data().groupId || null,
          access: doc.data().access || defaultFeatureList,
        }));

        const plans = recoveryPlansSnapshot.docs.map(doc => doc.id);
        const groupsData: Group[] = groupsSnapshot.docs.map(doc => ({
          id: doc.id,
          groupId: doc.data().groupId,
          recoveryPlan: doc.data().recoveryPlan,
          Admin: doc.data().Admin,
          Members: doc.data().Members || [],
          password: doc.data().password || '',
          access: doc.data().access || defaultFeatureList,
        }));

        setUsers(usersData);
        setRecoveryPlans(plans);
        setGroups(groupsData);

        // Automatically set the selected group based on the groupId prop
        const selected = groupsData.find(group => group.groupId === groupId);
        if (selected) {
          setSelectedGroup(selected);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [groupId]);

  // Update form fields when a group is selected
  useEffect(() => {
    if (selectedGroup) {
      setGroupName(selectedGroup.groupId);
      setRecoveryPlan(selectedGroup.recoveryPlan);
      setGroupPassword(selectedGroup.password || '');
      setFeatureList(selectedGroup.access || defaultFeatureList);

      const admin = users.find(user => user.email === selectedGroup.Admin);
      setAdminUser(admin || null);
    }
  }, [selectedGroup, users]);

  // Handle feature toggle
  const handleFeatureToggle = useCallback((feature: string) => {
    setFeatureList(prev => ({
      ...prev,
      [feature]: !prev[feature],
    }));
  }, []);

  // Handle updating the group
  const handleUpdateGroup = useCallback(async () => {
    if (!selectedGroup) return;

    try {
      const updatedGroup = {
        groupId: groupName,
        recoveryPlan,
        Members: selectedGroup.Members,
        password: groupPassword,
        access: featureList,
      };

      const groupDoc = doc(db, 'groups', selectedGroup.id);
      await updateDoc(groupDoc, updatedGroup);

      // Update user documents
      const userUpdates = users.map(user => {
        const userDoc = doc(db, 'users', user.id);
        if (selectedGroup.Members.includes(user.email)) {
          return updateDoc(userDoc, { groupId: groupName, recoveryPlan, access: featureList });
        } else if (user.groupId === selectedGroup.groupId) {
          return updateDoc(userDoc, { groupId: null, recoveryPlan: null, access: defaultFeatureList });
        }
        return Promise.resolve();
      });

      await Promise.all(userUpdates);
      onClose();
      window.location.reload();
      alert('Group updated');
    } catch (error) {
      console.error('Error updating group:', error);
      alert('An error occurred while updating the group. Please try again.');
    }
  }, [selectedGroup, users, groupName, recoveryPlan, groupPassword, featureList, onClose]);

  // Handle deleting the group
  const handleDeleteGroup = useCallback(async () => {
    if (!selectedGroup) return;

    try {
      // Clear group-related fields for members
      const userUpdates = users
        .filter(user => selectedGroup.Members.includes(user.email))
        .map(user =>
          updateDoc(doc(db, 'users', user.id), {
            groupId: null,
            recoveryPlan: null,
            recoveryPlanDate: null,
          })
        );

      await Promise.all(userUpdates);
      await deleteDoc(doc(db, 'groups', selectedGroup.id));
      setSelectedGroup(null);
      onClose();
      window.location.reload();
      alert('Group deleted');
    } catch (error) {
      console.error('Error deleting group:', error);
      alert('An error occurred while deleting the group. Please try again.');
    }
  }, [selectedGroup, users, onClose]);

  // Handle deleting a member from the group
  const handleDeleteMember = useCallback(async (memberEmail: string) => {
    if (!selectedGroup) return;

    try {
      // Remove member from the group's member list
      const updatedMembers = selectedGroup.Members.filter(member => member !== memberEmail);
      const updatedGroup = {
        ...selectedGroup,
        Members: updatedMembers,
      };

      // Update the group in Firestore
      const groupDoc = doc(db, 'groups', selectedGroup.id);
      await updateDoc(groupDoc, updatedGroup);

      // Update the user in Firestore to remove the groupId
      const member = users.find(user => user.email === memberEmail);
      if (member) {
        const userDoc = doc(db, 'users', member.id);
        await updateDoc(userDoc, {
          groupId: null,
          recoveryPlan: null,
        });
      }

      // Refresh the UI
      setSelectedGroup(updatedGroup);
    } catch (error) {
      console.error('Error deleting member:', error);
      alert('An error occurred while deleting the member. Please try again.');
    }
  }, [selectedGroup, users]);

  // Filtered members for display
  const filteredMembers = useMemo(() => {
    if (!selectedGroup) return [];
    return users.filter(user => selectedGroup.Members.includes(user.email));
  }, [selectedGroup, users]);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white h-5/6 w-11/12 lg:w-3/4 xl:w-2/3 p-8 rounded-3xl shadow-2xl relative overflow-auto">
        <button className="btn btn-sm btn-circle absolute right-2 top-2" onClick={onClose}>✕</button>
        <h2 className="text-3xl font-semibold text-gray-800 mb-6">Modify Group</h2>

        {selectedGroup && (
          <>
            <div className="mb-6">
              <label className="block text-lg font-medium text-gray-700 mb-2">Group Name</label>
              <div className="bg-blue-50 border border-blue-300 text-blue-700 p-3 rounded-md mb-4">
            <p className="text-sm">
              The group's name must be unique as it is used to identify the group.
            </p>
          </div>
              <input
                type="text"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                className="select select-bordered w-full"
              />
            </div>

            <div className="mb-6">
              <label className="block text-lg font-medium text-gray-700 mb-2">Recovery Plan</label>
              <div className="bg-blue-50 border border-blue-300 text-blue-700 p-3 rounded-md mb-4">
            <p className="text-sm">
              The recovery plan that will be assigned to all members of the group.
            </p>
          </div>
              <select
                value={recoveryPlan}
                onChange={(e) => setRecoveryPlan(e.target.value)}
                className="select select-bordered w-full"
              >
                <option value="" disabled>Select a recovery plan</option>
                {recoveryPlans.map(plan => (
                  <option key={plan} value={plan}>{plan}</option>
                ))}
              </select>
            </div>

            <div className="mb-6">
              <label className="block text-lg font-medium text-gray-700 mb-2">Group Password</label>
              <div className="bg-blue-50 border border-blue-300 text-blue-700 p-3 rounded-md mb-4">
            <p className="text-sm">
              The code that must be entered alongside the group's name when a user wants to join the group via app.
            </p>
          </div>
              <input
                value={groupPassword}
                onChange={(e) => setGroupPassword(e.target.value)}
                className="select select-bordered w-full"
              />
            </div>

            {/* Feature Access Section */}
            <div className="mb-6">
              <h3 className="text-xl font-medium text-gray-800 mb-4">Feature Access</h3>
                  <div className="bg-blue-50 border border-blue-300 text-blue-700 p-3 rounded-md mb-4">
            <p className="text-sm">
              The user’s feature access will be ignored and instead determined by the group’s features list while they are members.
            </p>
          </div>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                {Object.entries(featureList).map(([feature, enabled]) => (
                  <label key={feature} className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      checked={enabled}
                      onChange={() => handleFeatureToggle(feature)}
                      className="checkbox checkbox-primary"
                    />
                    {feature}
                  </label>
                ))}
              </div>
            </div>
            <div className="bg-blue-50 border border-blue-300 text-blue-700 p-3 rounded-md mb-4">
    
            <p className="text-sm">
              The group must have one admin assigned. Admins act as members but with more control over parameters of the group via dashboard.
            </p>

          </div>
            {/* Admin Section */}
            {adminUser && (
              <div className="mb-6">
                <h3 className="text-xl font-medium text-gray-800 mb-2">Admin</h3>
                <div className="p-3 border border-gray-200 rounded-lg bg-gray-100">
                  <p className="text-sm font-medium text-gray-900">{adminUser.name}</p>
                  <p className="text-sm text-gray-500">{adminUser.email}</p>
                </div>
              </div>
            )}

            {/* Members Section */}
            <div className="mb-6">
              <h3 className="text-xl font-medium text-gray-800 mb-4">Current Members</h3>
              <div className="max-h-64 overflow-y-auto border border-gray-200 rounded-lg">
                {filteredMembers.map(user => (
                  <div key={user.id} className="flex items-center justify-between p-3 border-b border-gray-100 hover:bg-gray-50">
                    <div>
                      <p className="text-sm font-medium text-gray-900">{user.name}</p>
                      <p className="text-sm text-gray-500">{user.email}</p>
                    </div>
                    {user.email !== selectedGroup.Admin ? (
                      <button
                        className="btn btn-sm bg-red-500 text-white"
                        onClick={() => handleDeleteMember(user.email)}
                      >
                        Delete
                      </button>
                    ) : (
                      <button className="btn btn-sm bg-gray-300 text-white" disabled>
                        Admin
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-start space-x-4">
              <button
                onClick={handleUpdateGroup}
                className="btn mx-2 p-2"
              >
                Update Group
              </button>

              <button
                onClick={handleDeleteGroup}
                className="btn mx-2 p-2 bg-red-500"
              >
                Delete Group
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ModifyGroup;
import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from "../../firebase";

interface ScheduleProps {
  userId: string | undefined;
}

interface ScheduleItem {
  id: string;
  completedAssessments: number;
  completedTreatments: number;
  recoveryPlan: string;
  startDate: string;
  status: string;
  streak: number;
  totalAssessments: number;
  totalTreatments: number;
  week1: Record<string, Record<string, boolean>>;
  week2?: Record<string, Record<string, boolean>>;
  // etc. up to week12...
  week3?: Record<string, Record<string, boolean>>;
  week4?: Record<string, Record<string, boolean>>;
  week5?: Record<string, Record<string, boolean>>;
  week6?: Record<string, Record<string, boolean>>;
  week7?: Record<string, Record<string, boolean>>;
  week8?: Record<string, Record<string, boolean>>;
  week9?: Record<string, Record<string, boolean>>;
  week10?: Record<string, Record<string, boolean>>;
  week11?: Record<string, Record<string, boolean>>;
  week12?: Record<string, Record<string, boolean>>;
}

const Schedule: React.FC<ScheduleProps> = ({ userId }) => {
  const [schedules, setSchedules] = useState<ScheduleItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<string | null>(null);

  useEffect(() => {
    setActiveTab(null);

    const fetchSchedules = async () => {
      try {
        const q = query(collection(db, 'user_schedules'), where('userId', '==', userId));
        const querySnapshot = await getDocs(q);
        const fetchedSchedules: ScheduleItem[] = [];

        querySnapshot.forEach((doc) => {
          const data = { id: doc.id, ...doc.data() } as ScheduleItem;
          fetchedSchedules.push(data);
        });

        // Sort by startDate DESCENDING (latest first)
        fetchedSchedules.sort((a, b) => 
          new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
        );
        setSchedules(fetchedSchedules);
      } catch (error) {
        console.error("Error fetching user's schedule: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSchedules();
  }, [userId]);

  if (loading) {
    return <p>Loading schedules...</p>;
  }

  return (
    <div className="w-full">
      {/* Horizontal scrollable container */}
      <div className="overflow-x-auto whitespace-nowrap py-4">
        <div className="inline-flex space-x-4 px-2">
          {schedules.map((item) => {
            // Color shading based on status
            const statusColor =
              item.status === 'active'
                ? 'border-l-4 border-green-500 bg-green-50 hover:bg-green-100'
                : item.status === 'terminated'
                ? 'border-l-4 border-red-500 bg-red-50 hover:bg-red-100'
                : 'border-l-4 border-gray-300 bg-white hover:bg-gray-100';

            return (
              <div
                key={item.id}
                className={`inline-block min-w-[220px] p-4 rounded-lg shadow-md cursor-pointer transition-colors ${statusColor}`}
                onClick={() => setActiveTab(item.id === activeTab ? null : item.id)}
              >
                <p className="font-semibold text-gray-700 mb-1 truncate">
                  {item.recoveryPlan}
                </p>
                <p className="text-gray-600 text-sm mb-1">
                  Start: <span className="font-medium">{item.startDate}</span>
                </p>
                <p className="text-gray-600 text-sm">
                  Status: <span className="font-medium capitalize">{item.status}</span>
                </p>
              </div>
            );
          })}
        </div>
      </div>

      {activeTab && (
        <div className="mt-4 bg-white p-6 rounded-lg shadow-lg border border-gray-200">
          <ScheduleCard item={schedules.find((sched) => sched.id === activeTab)!} />
        </div>
      )}
    </div>
  );
};

const ScheduleCard: React.FC<{ item: ScheduleItem }> = ({ item }) => {
  const daysOrder = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

  const formatTask = (task: string) => {
    if (task.includes('Treatment')) return 'Treatment';
    if (task.includes('Back Assessment')) return 'Back Assessment';
    if (task.includes('Arm Assessment')) return 'Arm Assessment';
    if (task.includes('Knee Assessment')) return 'Knee Assessment';
    return task.replace(/-/g, ' ');
  };

  const renderWeekProgress = (
    weekData: Record<string, Record<string, boolean>>,
    weekNumber: number
  ) => (
    <div key={`week${weekNumber}`} className="mb-8">
      <h3 className="text-lg font-semibold text-gray-800 mb-3">Week {weekNumber}</h3>
      <table className="table-auto w-full border border-gray-300">
        <thead className="bg-gray-50">
          <tr>
            {daysOrder.map((day) => (
              <th key={day} className="text-center border border-gray-200 p-2">
                {day.charAt(0).toUpperCase() + day.slice(1)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {daysOrder.map((day) => (
              <td key={day} className="border border-gray-200 align-top p-3">
                {weekData[day] ? (
                  <ul className="space-y-2">
                    {Object.entries(weekData[day]).map(([task, completed]) => (
                      <li
                        key={task}
                        className={`px-2 py-1 text-sm rounded-full text-center ${
                          completed ? 'bg-green-100 text-green-600' : 'bg-gray-100 text-gray-600'
                        }`}
                      >
                        {formatTask(task)} {completed ? '✓' : ''}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-gray-400 text-sm">No tasks</p>
                )}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <div>
      <div className="mb-4">
        <h2 className="text-xl font-bold text-gray-800">{item.recoveryPlan}</h2>
        <p className="text-gray-600 text-sm mt-1">
          <span className="font-medium">Start Date:</span> {item.startDate}
        </p>
        <p className="text-gray-600 text-sm">
          Status:{' '}
          <span
            className={`font-semibold capitalize ${
              item.status === 'active'
                ? 'text-green-500'
                : item.status === 'terminated'
                ? 'text-red-500'
                : 'text-gray-500'
            }`}
          >
            {item.status}
          </span>
        </p>
      </div>
      <div className="flex flex-wrap gap-6 mb-6">
        <div>
          <p className="text-sm text-gray-700">
            <span className="font-semibold text-purple-600">Treatments:</span>{' '}
            {item.completedTreatments} / {item.totalTreatments}
          </p>
        </div>
        <div>
          <p className="text-sm text-gray-700">
            <span className="font-semibold text-cyan-600">Assessments:</span>{' '}
            {item.completedAssessments} / {item.totalAssessments}
          </p>
        </div>
        <div>
          <p className="text-sm text-gray-700">
            <span className="font-semibold">Streak:</span> {item.streak}
          </p>
        </div>
      </div>
      {/* Weeks */}
      {renderWeekProgress(item.week1, 1)}
      {item.week2 && renderWeekProgress(item.week2, 2)}
      {item.week3 && renderWeekProgress(item.week3, 3)}
      {item.week4 && renderWeekProgress(item.week4, 4)}
      {item.week5 && renderWeekProgress(item.week5, 5)}
      {item.week6 && renderWeekProgress(item.week6, 6)}
      {item.week7 && renderWeekProgress(item.week7, 7)}
      {item.week8 && renderWeekProgress(item.week8, 8)}
      {item.week9 && renderWeekProgress(item.week9, 9)}
      {item.week10 && renderWeekProgress(item.week10, 10)}
      {item.week11 && renderWeekProgress(item.week11, 11)}
      {item.week12 && renderWeekProgress(item.week12, 12)}
    </div>
  );
};

export default Schedule;
import React, { useState, useEffect } from 'react';
import {
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
} from 'recharts';
import { DateTime } from 'luxon';
import { exportAssessmentsToCsv } from "../../utils";
import { Assessment } from '../../routes/userprofile';

//-------------- Types --------------

interface AssessmentGraphProps {
  backData: Assessment[];
  kneeData: Assessment[];
  armData: Assessment[];
  userEmail: string | null | undefined;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: any;
}

//-------------- Custom Tooltip --------------

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          border: '1px solid #ccc',
          fontSize: '12px',
        }}
      >
        <p>Date: {data.startTime?.toFormat('dd/MM/yyyy HH:mm')}</p>
        <p>Flexion: {data.flexibilityScore1}°</p>
        <p>Extension: {data.flexibilityScore2}°</p>
        <p>Left Rotation: {data.flexibilityScore3}°</p>
        <p>Right Rotation: {data.flexibilityScore4}°</p>
        <p>Left Lateral: {data.flexibilityScore5}°</p>
        <p>Right Lateral: {data.flexibilityScore6}°</p>
      </div>
    );
  }
  return null;
};

// If your line toggles differ between tabs, you can refine this
const initialLineVisibility = [true, true, true, true, true, true];

//-------------- Component --------------

const AssessmentGraph: React.FC<AssessmentGraphProps> = ({
  backData,
  kneeData,
  armData,
  userEmail
}) => {
  const [lineVisibility, setLineVisibility] = useState<boolean[]>(initialLineVisibility);
  const [filteredData, setFilteredData] = useState<Assessment[]>([]);
  const [activeTab, setActiveTab] = useState<string>('Back');

  const [showTable, setShowTable] = useState<boolean>(false);

  // Either a number (days) or 'all' for "All Time"
  const [daysShown, setDaysShown] = useState<number | 'all'>(7);

  // The date range displayed
  const [currentStart, setCurrentStart] = useState<DateTime>(
    DateTime.now().minus({ days: 6 }).startOf('day')
  );
  const [currentEnd, setCurrentEnd] = useState<DateTime>(DateTime.now().endOf('day'));

  // The time ranges (including "All Time" and "1 Year")
  const timeRanges = [
    { label: 'Single Day', value: 1 },
    { label: '7 Days', value: 7 },
    { label: '1 Month', value: 28 },
    { label: '1 Year', value: 365 },
    { label: 'All Time', value: 'all' },
  ];

  //--- Toggle line visibility (per index) ---
  const toggleLineVisibility = (index: number) => {
    const newVisibility = [...lineVisibility];
    newVisibility[index] = !newVisibility[index];
    setLineVisibility(newVisibility);
  };

  //--- Switch tabs: Back / Knee / Arm ---
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  //--- SHIFT RANGE: Previous ---
  const handlePrevious = () => {
    if (daysShown === 'all') return; // no shifting in All Time
    const offset = daysShown === 1 ? { days: 1 } : { days: daysShown };
    setCurrentStart(currentStart.minus(offset));
    setCurrentEnd(currentEnd.minus(offset));
  };

  //--- SHIFT RANGE: Next ---
  const handleNext = () => {
    if (daysShown === 'all') return; // no shifting in All Time
    const offset = daysShown === 1 ? { days: 1 } : { days: daysShown };
    setCurrentStart(currentStart.plus(offset));
    setCurrentEnd(currentEnd.plus(offset));
  };

  //--- TIME RANGE SELECT ---
  const handleTimeRangeChange = (value: number | 'all') => {
    setDaysShown(value);

    if (value === 'all') {
      // Combine all data from current tab to find earliest & latest
      let dataToFilter = backData;
      if (activeTab === 'Knee') dataToFilter = kneeData;
      else if (activeTab === 'Arm') dataToFilter = armData;

      if (!dataToFilter || dataToFilter.length === 0) {
        // Fallback
        setCurrentStart(DateTime.now().minus({ days: 1 }));
        setCurrentEnd(DateTime.now());
        return;
      }

      // Sort by earliest startTime
      const sortedByStart = [...dataToFilter].sort(
        (a, b) => (a.startTime?.toMillis() ?? 0) - (b.startTime?.toMillis() ?? 0)
      );
      const earliest = sortedByStart[0].startTime;
      const latest = sortedByStart[sortedByStart.length - 1].startTime;

      if (earliest && latest) {
        setCurrentStart(earliest.startOf('day'));
        setCurrentEnd(latest.endOf('day'));
      } else {
        // fallback
        setCurrentStart(DateTime.now().minus({ days: 1 }));
        setCurrentEnd(DateTime.now());
      }
    } else {
      // numeric days
      const newStart =
        value === 1
          ? DateTime.now().startOf('day')
          : DateTime.now().minus({ days: value - 1 }).startOf('day');
      const newEnd = DateTime.now().endOf('day');
      setCurrentStart(newStart);
      setCurrentEnd(newEnd);
    }
  };

  //--- Filter the relevant data by tab & date range ---
  useEffect(() => {
    let dataToFilter = backData;
    if (activeTab === 'Knee') {
      dataToFilter = kneeData;
    } else if (activeTab === 'Arm') {
      dataToFilter = armData;
    }

    const filtered = dataToFilter.filter((item) => {
      if (!item.startTime) return false;
      return item.startTime >= currentStart && item.startTime <= currentEnd;
    });
    setFilteredData(filtered);
  }, [activeTab, currentStart, currentEnd, backData, kneeData, armData, daysShown]);

  //--- Transform for Recharts ---
  const transformedData = filteredData.map((item) => ({
    ...item,
    timeStamp: item.startTime ? item.startTime.toMillis() : 0,
  }));

  //--- Generate ticks for the X-axis based on daysShown ---
  let ticks: number[] = [];
  if (daysShown === 'all') {
    // 6 or so ticks from earliest->latest
    const totalMs = currentEnd.toMillis() - currentStart.toMillis();
    const segmentCount = 6;
    const segmentSize = totalMs / segmentCount;
    ticks = Array.from({ length: segmentCount + 1 }).map((_, i) =>
      currentStart.toMillis() + i * segmentSize
    );
  } else if (daysShown === 1) {
    // single day -> 24 hourly ticks
    ticks = Array.from({ length: 24 }).map((_, i) =>
      currentStart.plus({ hours: i }).toMillis()
    );
  } else if (daysShown === 7) {
    // 7 days -> daily
    ticks = Array.from({ length: 7 }).map((_, i) =>
      currentStart.plus({ days: i }).toMillis()
    );
  } else if (daysShown === 28) {
    // 1 month -> 4 weekly
    ticks = Array.from({ length: 4 }).map((_, i) =>
      currentStart.plus({ weeks: i }).toMillis()
    );
  } else if (daysShown === 365) {
    // 1 year -> ~12 monthly
    const monthsCount = 12;
    ticks = Array.from({ length: monthsCount }).map((_, i) =>
      currentStart.plus({ months: i }).toMillis()
    );
  }

  //--- Format tick labels ---
  const formatTick = (tick: number) => {
    const dt = DateTime.fromMillis(tick);
    if (daysShown === 'all' || daysShown > 7) {
      return dt.toFormat('dd/MMM');
    } else if (daysShown === 7) {
      return dt.toFormat('EEE dd/MMM');
    } else {
      // single day
      return dt.toFormat('HH:mm');
    }
  };

  //--- Disable forward arrow if at "today" or if "all" ---
  const isNextDisabled = daysShown === 'all' || currentEnd >= DateTime.now().endOf('day');

  //-------------- Render --------------
  return (
    <div className="card" style={{ height: '500px' }}>

      {/* --- Tab Controls (Back / Knee / Arm) --- */}
      <div className="join" style={{ marginBottom: '8px' }}>
        {["Back", "Knee", "Arm"].map((tab) => (
          <input
            key={tab}
            type="radio"
            name="tabs"
            className={`join-item btn btn-square ${activeTab === tab ? "btn-active" : ""}`}
            aria-label={tab}
            checked={activeTab === tab}
            onChange={() => handleTabChange(tab)}
          />
        ))}
      </div>

      {/* --- Export Button --- */}
      <span
        className="text-blue-500 cursor-pointer underline text-center"
        style={{ fontSize: '12px' }}
        onClick={() =>
          exportAssessmentsToCsv("assessments.csv", backData, kneeData, armData, userEmail ?? '')
        }
      >
        Export all to CSV
      </span>

      {/* --- Controls Row (Date Navigation + Time Range + Table Toggle) --- */}
      <div className="flex justify-between items-center mb-4 p-4">
        {/* Left arrow */}
        <button className="btn" style={{ fontSize: '20px' }} onClick={handlePrevious}>
          ←

        </button>
        {/* Current Range */}
        <h2>
          {currentStart.toFormat('dd/MM/yyyy')} - {currentEnd.toFormat('dd/MM/yyyy')}
        </h2>
        {/* Right arrow */}
        <button
          className="btn"
          style={{ fontSize: '20px' }}
          onClick={handleNext}
          disabled={isNextDisabled}
        >
          →
        </button>

        {/* Time Range Dropdown */}
        <div className="flex">
          <select
            className="dropdown-selector p-2 rounded border"
            value={daysShown}
            onChange={(e) => {
              const val = e.target.value === 'all' ? 'all' : Number(e.target.value);
              handleTimeRangeChange(val);
            }}
          >
            {timeRanges.map((range) => (
              <option key={range.value} value={range.value}>
                {range.label}
              </option>
            ))}
          </select>
        </div>

        {/* Table Display Toggle */}
        <h3 style={{ position: 'relative', left: '0px', fontSize: '14px' }}>
          Table Display
        </h3>
        <label className="switch" style={{ right: '30px' }}>
          <input
            type="checkbox"
            checked={showTable}
            onChange={() => setShowTable(!showTable)}
          />
          <span className="slider"></span>
        </label>
      </div>

      {/* --- Chart vs. Table --- */}
      {!showTable ? (
        // --- Chart View ---
        <div style={{ height: '200px' }}>
          <ResponsiveContainer
            key={`${daysShown}-${currentStart.toMillis()}-${currentEnd.toMillis()}-${activeTab}`}
          >
            <ComposedChart
              data={transformedData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <Tooltip content={<CustomTooltip />} />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                interval={0}
                ticks={ticks}
                tickFormatter={formatTick}
                dataKey="timeStamp"
                type="number"
                domain={[currentStart.toMillis(), currentEnd.toMillis()]}
                scale="time"
                style={{ fontSize: '12px' }}
              />
              <YAxis
                domain={[0, 120]}
                ticks={[30, 60, 90, 120]}
                axisLine={{ stroke: '#AAAAAA' }}
                tickLine={{ stroke: '#AAAAAA' }}
                tick={{ fill: '#000000', fontSize: '12px' }}
              />
              {/* Show lines depending on the tab and lineVisibility */}
              {/* For "Back" we might show all 6 lines */}
              {lineVisibility[0] && (
                <Line
                  type="monotone"
                  strokeWidth={1.5}
                  dataKey="flexibilityScore1"
                  stroke="#4880B8"
                  dot={{ r: 2 }}
                  isAnimationActive={false}
                />
              )}
              {lineVisibility[1] && activeTab === 'Back' && (
                <Line
                  type="monotone"
                  strokeWidth={1.5}
                  dataKey="flexibilityScore2"
                  stroke="#73E38C"
                  dot={{ r: 2 }}
                  isAnimationActive={false}
                />
              )}
              {lineVisibility[2] && activeTab === 'Back' && (
                <Line
                  type="monotone"
                  strokeWidth={1.5}
                  dataKey="flexibilityScore3"
                  stroke="#9747ff"
                  dot={{ r: 2 }}
                  isAnimationActive={false}
                />
              )}
              {lineVisibility[3] && activeTab === 'Back' && (
                <Line
                  type="monotone"
                  strokeWidth={1.5}
                  dataKey="flexibilityScore4"
                  stroke="#56c1ca"
                  dot={{ r: 2 }}
                  isAnimationActive={false}
                />
              )}
              {lineVisibility[4] && activeTab === 'Back' && (
                <Line
                  type="monotone"
                  strokeWidth={1.5}
                  dataKey="flexibilityScore5"
                  stroke="#F60E38"
                  dot={{ r: 2 }}
                  isAnimationActive={false}
                />
              )}
              {lineVisibility[5] && activeTab === 'Back' && (
                <Line
                  type="monotone"
                  strokeWidth={1.5}
                  dataKey="flexibilityScore6"
                  stroke="#FFA452"
                  dot={{ r: 2 }}
                  isAnimationActive={false}
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>

          {/* Legend-like buttons to toggle lines (for the "Back" tab, etc.) */}
          <ul className="menu menu-horizontal bg-base-100 rounded-box">
            {activeTab === 'Back' && (
              <>
                <li>
                  <a
                    className="bg-base-100 btn"
                    style={{ color: '#4880B8', opacity: lineVisibility[0] ? 1 : 0.2 }}
                    onClick={() => toggleLineVisibility(0)}
                  >
                    Flexion
                  </a>
                </li>
                <li>
                  <a
                    className="bg-base-100 btn"
                    style={{ color: '#73E38C', opacity: lineVisibility[1] ? 1 : 0.2 }}
                    onClick={() => toggleLineVisibility(1)}
                  >
                    Extension
                  </a>
                </li>
                <li>
                  <a
                    className="bg-base-100 btn"
                    style={{ color: '#9747ff', opacity: lineVisibility[2] ? 1 : 0.2 }}
                    onClick={() => toggleLineVisibility(2)}
                  >
                    Left Rotation
                  </a>
                </li>
                <li>
                  <a
                    className="bg-base-100 btn"
                    style={{ color: '#56c1ca', opacity: lineVisibility[3] ? 1 : 0.2 }}
                    onClick={() => toggleLineVisibility(3)}
                  >
                    Right Rotation
                  </a>
                </li>
                <li>
                  <a
                    className="bg-base-100 btn"
                    style={{ color: '#F60E38', opacity: lineVisibility[4] ? 1 : 0.2 }}
                    onClick={() => toggleLineVisibility(4)}
                  >
                    Left Lateral
                  </a>
                </li>
                <li>
                  <a
                    className="bg-base-100 btn"
                    style={{ color: '#FFA452', opacity: lineVisibility[5] ? 1 : 0.2 }}
                    onClick={() => toggleLineVisibility(5)}
                  >
                    Right Lateral
                  </a>
                </li>
              </>
            )}
            {activeTab === 'Knee' && (
              <>
                <li>
                  <a
                    className="bg-base-100 btn"
                    style={{ color: '#4880B8', opacity: lineVisibility[0] ? 1 : 0.2 }}
                    onClick={() => toggleLineVisibility(0)}
                  >
                    Extension
                  </a>
                </li>
              </>
            )}
            {activeTab === 'Arm' && (
              <>
                <li>
                  <a
                    className="bg-base-100 btn"
                    style={{ color: '#4880B8', opacity: lineVisibility[0] ? 1 : 0.2 }}
                    onClick={() => toggleLineVisibility(0)}
                  >
                    Flexion
                  </a>
                </li>
              </>
            )}
          </ul>
        </div>
      ) : (
        // --- TABLE VIEW ---
        <div className="table-responsive" style={{ height: '300px' }}>
          {/* 
            Based on activeTab, show columns relevant to Back, Knee, or Arm 
            The existing code is fine, just refactor if needed 
          */}
          {activeTab === 'Back' && (
            <table className="table table-zebra" style={{ fontSize: '12px' }}>
              <thead>
                <tr>
                  <th>Device</th>
                  <th>Start Time</th>
                  <th>Flexion</th>
                  <th>Extension</th>
                  <th>Left Rotation</th>
                  <th>Right Rotation</th>
                  <th>Left Lateral</th>
                  <th>Right Lateral</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((row) => (
                  <tr key={row.id}>
                    <td>{row.deviceId}</td>
                    <td>{row.startTime?.toFormat('yyyy-MM-dd HH:mm')}</td>
                    <td>{row.flexibilityScore1}</td>
                    <td>{row.flexibilityScore2}</td>
                    <td>{row.flexibilityScore3}</td>
                    <td>{row.flexibilityScore4}</td>
                    <td>{row.flexibilityScore5}</td>
                    <td>{row.flexibilityScore6}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {activeTab === 'Knee' && (
            <table className="table table-zebra" style={{ fontSize: '12px' }}>
              <thead>
                <tr>
                  <th>Device</th>
                  <th>Start Time</th>
                  <th>Extension</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((row) => (
                  <tr key={row.id}>
                    <td>{row.deviceId}</td>
                    <td>{row.startTime?.toFormat('yyyy-MM-dd HH:mm')}</td>
                    <td>{row.flexibilityScore1}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {activeTab === 'Arm' && (
            <table className="table table-zebra" style={{ fontSize: '12px' }}>
              <thead>
                <tr>
                  <th>Device</th>
                  <th>Start Time</th>
                  <th>Flexion</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((row) => (
                  <tr key={row.id}>
                    <td>{row.deviceId}</td>
                    <td>{row.startTime?.toFormat('yyyy-MM-dd HH:mm')}</td>
                    <td>{row.flexibilityScore1}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default AssessmentGraph;
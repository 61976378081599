import React, { useState, useEffect } from 'react';
import {
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
} from 'recharts';
import { DateTime } from 'luxon';
import { exportTreatmentsToCsv } from '../../utils';
import { Treatment } from '../../routes/userprofile';

//-------------- Types --------------

interface TreatmentGraphProps {
  data: Treatment[];
  userEmail: string | null | undefined;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: any;
}

//-------------- Custom Tooltip --------------

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const d = payload[0].payload;
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          border: '1px solid #ccc',
          fontSize: '12px',
        }}
      >
        <p>Date: {d.startTime?.toFormat('dd/MM/yyyy HH:mm')}</p>
        <p>Treatment: {d.length} minutes</p>
        <p>Temperature: {d.temperaturePost}°C</p>
        <p>Pain Before: {d.painScorePre}</p>
        <p>Pain After: {d.painScorePost}</p>
        <p>Product: {Object.keys(d.sequence)}</p>
        <p>Device ID: {d.deviceId}</p>
      </div>
    );
  }
  return null;
};

//-------------- Initial Values --------------

const initialLineVisibility = [true, true, true, true]; // if you have multiple lines or bars

//-------------- Component --------------

const TreatmentGraph: React.FC<TreatmentGraphProps> = ({ data, userEmail }) => {
  const [lineVisibility, setLineVisibility] = useState<boolean[]>(initialLineVisibility);
  const [filteredData, setFilteredData] = useState<Treatment[]>([]);
  const [showTable, setShowTable] = useState<boolean>(false);

  // either a number (days) or 'all' for "All Time"
  const [daysShown, setDaysShown] = useState<number | 'all'>(7);

  // The date range we’re displaying
  const [currentStart, setCurrentStart] = useState<DateTime>(
    DateTime.now().minus({ days: 6 }).startOf('day')
  );
  const [currentEnd, setCurrentEnd] = useState<DateTime>(DateTime.now().endOf('day'));

  // The various time ranges
  const timeRanges = [
    { label: 'Single Day', value: 1 },
    { label: '7 Days', value: 7 },
    { label: '1 Month', value: 28 },
    { label: '1 Year', value: 365 },    // <-- Replaced 3 Months with 1 Year
    { label: 'All Time', value: 'all' },
  ];

  //--- TOGGLE LINES (if you have multiple data lines) ---
  const toggleLineVisibility = (index: number) => {
    const newVisibility = [...lineVisibility];
    newVisibility[index] = !newVisibility[index];
    setLineVisibility(newVisibility);
  };

  //--- SHIFTING THE RANGE: PREVIOUS ---
  const handlePrevious = () => {
    if (daysShown === 'all') {
      // do nothing if "All Time"
      return;
    }
    const offset = daysShown === 1 ? { days: 1 } : { days: daysShown };
    setCurrentStart(currentStart.minus(offset));
    setCurrentEnd(currentEnd.minus(offset));
  };

  //--- SHIFTING THE RANGE: NEXT ---
  const handleNext = () => {
    if (daysShown === 'all') {
      // do nothing if "All Time"
      return;
    }
    const offset = daysShown === 1 ? { days: 1 } : { days: daysShown };
    setCurrentStart(currentStart.plus(offset));
    setCurrentEnd(currentEnd.plus(offset));
  };

  //--- TIME RANGE SELECTION ---
  const handleTimeRangeChange = (value: number | 'all') => {
    setDaysShown(value);

    if (value === 'all') {
      // If no data, just set an arbitrary range
      if (!data || data.length === 0) {
        setCurrentStart(DateTime.now().minus({ days: 1 }));
        setCurrentEnd(DateTime.now());
        return;
      }
      // Find earliest & latest startTime
      const sortedByStart = [...data].sort(
        (a, b) => (a.startTime?.toMillis() ?? 0) - (b.startTime?.toMillis() ?? 0)
      );
      const earliest = sortedByStart[0].startTime;
      const latest = sortedByStart[sortedByStart.length - 1].startTime;
      if (earliest && latest) {
        setCurrentStart(earliest.startOf('day'));
        setCurrentEnd(latest.endOf('day'));
      } else {
        // fallback
        setCurrentStart(DateTime.now().minus({ days: 1 }));
        setCurrentEnd(DateTime.now());
      }
    } else {
      // numeric days
      const newStart =
        value === 1
          ? DateTime.now().startOf('day')
          : DateTime.now().minus({ days: value - 1 }).startOf('day');
      const newEnd = DateTime.now().endOf('day');
      setCurrentStart(newStart);
      setCurrentEnd(newEnd);
    }
  };

  //--- FILTER DATA BY [currentStart, currentEnd] ---
  useEffect(() => {
    const filterData = (start: DateTime, end: DateTime, dataSource: Treatment[]) => {

      return dataSource.filter((item) => {
        if (!item.startTime) return false;
        return item.startTime >= start && item.startTime <= end;
      });
    };
    const filtered = filterData(currentStart, currentEnd, data);
    setFilteredData(filtered);
  }, [data, currentStart, currentEnd]);

  //--- Transform for Recharts ---
  const transformedData = filteredData.map((item) => ({
    ...item,
    timeStamp: item.startTime ? item.startTime.toMillis() : 0,
    product: item.sequence ? Object.keys(item.sequence)[0] : '',
    index: item.sequence ? Object.values(item.sequence)[0] : 0,
  }));

  //--- TICKS FOR X-AXIS ---
  let ticks: number[] = [];
  if (daysShown === 'all') {
    // All Time -> 6 or so ticks from earliest->latest
    const totalMs = currentEnd.toMillis() - currentStart.toMillis();
    const segmentCount = 6;
    const segmentSize = totalMs / segmentCount;
    ticks = Array.from({ length: segmentCount + 1 }).map((_, i) =>
      currentStart.toMillis() + i * segmentSize
    );
  } else if (daysShown === 1) {
    // single day -> 24 hourly ticks
    ticks = Array.from({ length: 24 }).map((_, i) =>
      currentStart.plus({ hours: i }).toMillis()
    );
  } else if (daysShown === 7) {
    // 7 days -> daily ticks
    ticks = Array.from({ length: 7 }).map((_, i) =>
      currentStart.plus({ days: i }).toMillis()
    );
  } else if (daysShown === 28) {
    // 1 month ~4 weekly ticks
    ticks = Array.from({ length: 4 }).map((_, i) =>
      currentStart.plus({ weeks: i }).toMillis()
    );
  } else if (daysShown === 365) {
    // 1 year -> ~12 monthly ticks
    const monthsCount = 12;
    ticks = Array.from({ length: monthsCount }).map((_, i) =>
      currentStart.plus({ months: i }).toMillis()
    );
  }

  //--- FORMAT TICK LABELS ---
  const formatTick = (tickMs: number) => {
    const dt = DateTime.fromMillis(tickMs);
    // If 7 days or less => show day/time
    // If 'all' or more than 7 => show dd/MMM
    if (daysShown === 'all' || daysShown > 7) {
      return dt.toFormat('dd/MMM');
    } else if (daysShown === 7) {
      return dt.toFormat('EEE dd/MMM');
    } else {
      // single day
      return dt.toFormat('HH:mm');
    }
  };

  //--- DISABLE FORWARD IF WE'RE AT TODAY ---
  const isNextDisabled = daysShown === 'all' || currentEnd >= DateTime.now().endOf('day');

  //-------------- RENDER --------------
  return (
    <div className="card" style={{ height: '500px' }}>
      {/* ---- Export Button ---- */}
      <span
        className="text-blue-500 cursor-pointer underline text-center"
        style={{ fontSize: '12px' }}
        onClick={() => {
          exportTreatmentsToCsv("treatments.csv", data, userEmail ?? '');
        }}
      >
        Export all to CSV
      </span>

      {/* ---- Controls Row ---- */}
      <div className="flex justify-between items-center mb-4 p-4">
        {/* Left arrow */}
        <button className="btn" style={{ fontSize: '20px' }} onClick={handlePrevious}>
          ←
        </button>
        {/* Current date range */}
        <h2>
          {currentStart.toFormat('dd/MM/yyyy')} - {currentEnd.toFormat('dd/MM/yyyy')}
        </h2>
        {/* Right arrow */}
        <button
          className="btn"
          style={{ fontSize: '20px' }}
          onClick={handleNext}
          disabled={isNextDisabled}
        >
          →
        </button>

        {/* Time Range Dropdown */}
        <div className="flex">
          <select
            className="dropdown-selector p-2 rounded border"
            value={daysShown}
            onChange={(e) => {
              const val = e.target.value === 'all' ? 'all' : Number(e.target.value);
              handleTimeRangeChange(val);
            }}
          >
            {timeRanges.map((range) => (
              <option key={range.value} value={range.value}>
                {range.label}
              </option>
            ))}
          </select>
        </div>

        {/* Table Display Toggle */}
        <h3 style={{ position: 'relative', left: '0px', fontSize: '14px' }}>
          Table Display
        </h3>
        <label className="switch" style={{ right: '30px' }}>
          <input
            type="checkbox"
            checked={showTable}
            onChange={() => setShowTable(!showTable)}
          />
          <span className="slider"></span>
        </label>
      </div>

      {/* ---- Chart vs. Table ---- */}
      {!showTable ? (
        //--- CHART VIEW ---
        <div style={{ height: '200px' }}>
          <ResponsiveContainer
            key={`${daysShown}-${currentStart.toMillis()}-${currentEnd.toMillis()}`}
          >
            <ComposedChart
              data={transformedData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <Tooltip content={<CustomTooltip />} />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                interval={0}
                ticks={ticks}
                tickFormatter={formatTick}
                dataKey="timeStamp"
                type="number"
                domain={[currentStart.toMillis(), currentEnd.toMillis()]}
                scale="time"
                style={{ fontSize: '12px' }}
              />
              {/* One visible axis, plus any hidden ones if needed */}
              <YAxis
                yAxisId="t"
                domain={[0, 30]}
                ticks={[10, 20, 30]}
                axisLine={{ stroke: '#AAAAAA' }}
                tickLine={{ stroke: '#AAAAAA' }}
                tick={{ fill: '#000000', fontSize: '12px' }}
              />
              {lineVisibility[0] && (
                <Bar
                  yAxisId="t"
                  fill="#9747ff"
                  dataKey="length"
                  barSize={4}
                  isAnimationActive={false}
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      ) : (
        //--- TABLE VIEW ---
        <div className="table-responsive" style={{ height: '300px' }}>
          <table className="table table-zebra" style={{ fontSize: '12px' }}>
            <thead>
              <tr>
                <th>Device</th>
                <th>Device Treatment #</th>
                <th>Start Time</th>
                <th>Duration (mins)</th>
                <th>End Time</th>
                <th>Product</th>
                <th>Sequence</th>
                <th>Temperature Pre</th>
                <th>Temperature Post</th>
                <th>Pain Score Pre</th>
                <th>Pain Score Post</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((row, i) => (
                <tr key={`${row.deviceId}-${i}`}>
                  <td>{row.deviceId}</td>
                  <td>{row.deviceTN}</td>
                  <td>{row.startTime?.toFormat('yyyy-MM-dd HH:mm')}</td>
                  <td>{row.length}</td>
                  <td>{row.endTime?.toFormat('yyyy-MM-dd HH:mm')}</td>
                  <td>{Object.keys(row.sequence || {})[0]}</td>
                  <td>{Object.values(row.sequence || {})[0]}</td>
                  <td>{row.temperaturePre}</td>
                  <td>{row.temperaturePost}</td>
                  <td>{row.painScorePre}</td>
                  <td>{row.painScorePost}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TreatmentGraph;